import React,{ Component } from 'react';
import $ from 'jquery';
import { Form,Button } from 'react-bootstrap';

class Fields extends Component {
	constructor(props) {
        super(props);
	 	this.state ={}
    }
	
	filterMdsForms=(event)=>{
		let item = event.target.name;
		let searchQuery = event.target.value.toLowerCase();
		let mdsSheetNames = this.props.mdsSheets;
		console.log('mdsSheetNames->',mdsSheetNames);
		if(searchQuery.length > 1){
			$(".mds-group").hide();
			mdsSheetNames.map((sheet,i)=>{
				let sheetName = sheet.MDSName.toLowerCase();
				sheetName = sheetName.substring(0, 10);
				//if(sheetName.indexOf(value) > -1){
				if(sheetName.includes(searchQuery) || sheet.Mode.toLowerCase().includes(searchQuery) || sheet.Version == searchQuery){
					let sheetValue = sheet.MDSName.replaceAll(' ','-');
					$("#"+sheetValue).show();
				}
			})
		}else{
			$(".mds-group").show();
		}
	}
	
	render(){
		const { mdsSpreadsheetId, addField, addNewPreField, mdsSheets, addParentSets, parentDataSets, addNewGroup, textfields, basicfields, functionfields, gridfields, formattingfields, getPlaceholder,dataMode,mdsSpreadsheets, loadMdsSpreadSheet} = this.props;
		
		var textfieldsHtml = textfields.map(function(val,i) {
			let field = val.split(':');
			return (
				<input key={i} className="field-btn" type="button" value={field[1]} onClick={()=>addField(field[0],field[1])} /> 
			);
		})
		
		var basicfieldsHtml = basicfields.map(function(val,i) {
			let field = val.split(':');
			return (
				<input key={i} className="field-btn" type="button" value={field[1]} onClick={()=>addField(field[0],field[1])} /> 
			);
		})
		
		var functionfieldsHtml = functionfields.map(function(val,i) {
			let field = val.split(':');
			return (
				<input key={i} className="field-btn" type="button" value={field[1]} onClick={()=>addField(field[0],field[1])} /> 
			);
		})
		
		var gridfieldsHtml = gridfields.map(function(val,i) {
			let field = val.split(':');
			return (
				<input key={i} className="field-btn" type="button" value={field[1]} onClick={()=>addField(field[0],field[1])} /> 
			);
		})
		
		var formattingfieldsHtml = formattingfields.map(function(val,i) {
			let field = val.split(':');
			return (
				<input key={i} className="field-btn" type="button" value={field[1]} onClick={()=>addField(field[0],field[1])} /> 
			);
		})
		let optionHtmlsheets = '';
		if(mdsSpreadsheets){
			optionHtmlsheets = mdsSpreadsheets.map(function(val,i) {
				return (
					<option value={val["spreadsheetId"]} key={i}>{val["title"]}</option>
				);
			})
		}
		
        return (
		<div>
		{dataMode ?
        <div className="accordion" id="field">
			{dataMode == 'MDS' ?
			<div className="card">
				<div className="card-header" id="fieldhead1">
					<a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#field1"
					aria-expanded="true" aria-controls="field1">Index & Text Fields</a>
				</div>

				<div id="field1" className="collapse" aria-labelledby="fieldhead1" data-parent="#field">
					<div className="card-body">
						{textfieldsHtml}
					</div>
				</div>
			</div>
			:null}
			{dataMode == 'MDS' ?
			<div className="card">
				<div className="card-header" id="fieldhead2">
					<a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#field2"
					aria-expanded="true" aria-controls="field2">Basic Fields</a>
				</div>

				<div id="field2" className="collapse" aria-labelledby="fieldhead2" data-parent="#field">
					<div className="card-body">
					{basicfieldsHtml}
					</div>
				</div>
			</div>
			:null}
			{dataMode == 'MDS' ?
			<div className="card">
				<div className="card-header" id="fieldhead3">
					<a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#field3"
					aria-expanded="true" aria-controls="field3">Functions</a>
				</div>

				<div id="field3" className="collapse" aria-labelledby="fieldhead3" data-parent="#field">
					<div className="card-body">
					{functionfieldsHtml}
					</div>
				</div>
			</div>
			:null}
			{dataMode == 'MDS' ?
			<div className="card">
				<div className="card-header" id="fieldhead4">
					<a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#field4"
					aria-expanded="true" aria-controls="field4">Grids</a>
				</div>

				<div id="field4" className="collapse" aria-labelledby="fieldhead4" data-parent="#field">
					<div className="card-body">
					{gridfieldsHtml}
					</div>
				</div>
			</div>
			:null}
			{/* dataMode != 'Layout' && dataMode != 'MDS' && dataMode != 'TestForm' && dataMode != 'ReviewForm' && dataMode != 'mdsFieldCopy' ?
			<div className="card">
				<div className="card-header" id="fieldhead5">
					<a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#field5"
					aria-expanded="true" aria-controls="field5">Formatting</a>
				</div>

				<div id="field5" className="collapse" aria-labelledby="fieldhead5" data-parent="#field">
					<div className="card-body">
					{formattingfieldsHtml}
					</div>
				</div>
			</div>
			:null */}
			{dataMode == 'Child' ?
				<div className="load-mds-filter">
					<label> Filter MDS </label>
					<input type="text" className="form-control" name="filterMds" onChange={this.filterMdsForms}/>
				</div>
			:null}
			
			{dataMode == 'Child' && mdsSpreadsheets.length > 0 ?
			<div className="load-mds-filter">
				<label> Select MDS File </label>
				<select className="form-control" value={mdsSpreadsheetId} name="mdsSpreadsheetId" onChange={loadMdsSpreadSheet}>
					<option value="">Please Select</option>
					{optionHtmlsheets}
				</select>
			</div>
			:null}
			
			{dataMode == 'Child' ?
			<div className="card">
				<div className="card-header" id="fieldhead6">
					<a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#field6"
					aria-expanded="true" aria-controls="field6">Master Data Sets</a>
				</div>

				<div id="field6" className="collapse" aria-labelledby="fieldhead6">
					<div className="card-body">
						{mdsSheets.map((val, i) => (
							<div id={val.MDSName} key={i} className={"mds-group set-btn set-"+i+' mode-'+val.Mode} >
								<input className="field-btn" type="button" value={val.MDSName} onClick={()=>addParentSets(val.MDSName, i)} />
							</div>
						))}
					</div>
				</div>
			</div>
			:null}
			{dataMode == 'Child' ?
			<div className="card">
				<div className="card-header" id="fieldhead7">
					<a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#field7"
					aria-expanded="true" aria-controls="field7">Parent Groups</a>
				</div>

				<div id="field7" className="collapse" aria-labelledby="fieldhead7" data-parent="#field">
					<div className="card-body">
						{parentDataSets.map((comment,i) =>{
							if(i > 0 && comment[126] == 'Y'){
								//let type = comment[5].replace('Child Header ','H');
								let type = comment[5]
								let plh = comment[4]+'('+type+')';
								return(
									<input key={i} className="field-btn" type="button" value={plh} onClick={()=>addNewGroup(comment[0],comment[5], comment[10])}/>
								); 
							}
						})}
					</div>
				</div>
			</div>
			:null}
			{dataMode == 'Child' ?
			<div className="card">
				<div className="card-header" id="fieldhead8">
					<a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#field8"
					aria-expanded="true" aria-controls="field8">Header Groups</a>
				</div>

				<div id="field8" className="collapse" aria-labelledby="fieldhead8" data-parent="#field">
					<div className="card-body">
						{parentDataSets.map((comment,i) =>{
							if(i > 0 && comment[127] == 'Y'){
								let type = comment[5].replace('Child Header ','H')
								let plh = comment[4]+'('+type+')';
								return(
									<input key={i} className="field-btn" type="button" value={plh} onClick={()=>addNewGroup(comment[0],comment[5], comment[10])}/>
								); 
							}
						})}
					</div>
				</div>
			</div>
			:null}
			{dataMode == 'Child' ?
			<div className="card">
				<div className="card-header" id="fieldhead9">
					<a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#field9"
					aria-expanded="true" aria-controls="field9">Fields</a>
				</div>

				<div id="field9" className="collapse" aria-labelledby="fieldhead8" data-parent="#field">
					<div className="card-body">
						{parentDataSets.map((comment,i) =>{
							//console.log('comment->',comment);
							//let placeholder = getPlaceholder(comment[5]);
							if(i > 0){ 
								return(
									<input key={i} className="field-btn" type="button" value={comment[4]} onClick={()=>addNewPreField(comment)}/>
								); 
							}
						})}
					</div>
				</div>
			</div>
			:null}
		</div>
		:null}		
		</div>            
	)}
}

export default Fields;