import React,{ Component } from 'react';
import $ from 'jquery';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import BasicTextFields from "../../component/MainData/InlineUpload.js";
import Stack from '@mui/material/Stack';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import arrayMove from "../arrayMove";
import {DebounceInput} from 'react-debounce-input';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';

class BuildDetails extends Component {
	constructor(props) {
        super(props);
	 	this.state ={
			//deviceTypes:[],
			startDate: '',
		}
		this.handleChange = this.handleChange.bind(this); 
    }
	
	handleChange = date => {
        let newDate = (date.getMonth()+1)+'/'+date.getDate()+'/'+date.getFullYear();
        this.setState({
          startDate: date,date
        });
		this.props.getDueDate(newDate);
    };
	
	getValue=(event)=>{
        let name = event.target.name;
        let res = event.target.value;
		if(name == 'sliderStepValue' && (res < 0 || res > 100)){
			alert('The Step value should be minimun ".000005" and maximum "100".');
			return false;
		}
		if(name == 'SelectedKeyed'){
			this.setState({[event.target.name]:event.target.value});
		}
		if(name == 'addParentMds'){
			this.props.addParentSets(res);
		}
		this.props.getValue(event);
	}
	
	showErrorInfo=()=>{
		$('#redoErrorInfo').modal('show');
	}
	
	getRedo=(event)=>{
		if($("input[name='redoInfo']").prop("checked") == true){
			$('#redoModal').modal('show');
		}else{
			$('#redoModal').modal('hide');
		}
		this.props.getValue(event);
	}
	
	onSortEnd = ({oldIndex, newIndex}) => {
		let optionkeys = this.props.defaultProps.changeAnsInpText.split(',');
		optionkeys = optionkeys.filter(function(x) {
			 return x !== '';
		});
		optionkeys = arrayMove(optionkeys, oldIndex, newIndex);
		this.props.changeOptionKey(optionkeys);
	};
	
	
	showDimantionPop=(val,vl)=>{
		
	}

	render(){
		const {allData,addField,fieldId,getValue,delegateTypes,deviceTypes,types,visitTypes,showBuildEdit,showFieldEdit,companies,assignTracks,groupId,facilityIds,parentDataSets,mdsSheets,dataMode,showTrackerDetails,showTrackerViewFun,trackerRow,persons,ogTags,trackerTableData,statusLogs,catList,role} = this.props;
		let href = window.location.href.split('?')[0];
		let categoryOption = catList.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		let that = this;
		const HtmlTooltip = styled(({ className, ...props }) => (
		  <Tooltip {...props} classes={{ popper: className }} />
		))(({ theme }) => ({
		  [`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#333',
			color: '#fff',
			maxWidth: 420,
			fontSize: theme.typography.pxToRem(11),
			border: '1px solid #000',
		  },
		}));
		const DragHandle = sortableHandle(() => <div className="showcase2"></div>);
		let loadDataSet = this.props.defaultProps.loadDataSet;
		let defaultProps = this.props.defaultProps;
		let defineKey = '';
		
		let dimensionTxtValue = defaultProps.hiddenDimensions;
		
		let optionkeys = [];
		let optionsKeyHtml = '';
		if((defaultProps.changeAnsOpt || defaultProps.changeRadioPlus || defaultProps.changeAnsOptKey || defaultProps.changeAnsOptHidden) && defaultProps.changeAnsInpText !=''){
			optionkeys = defaultProps.changeAnsInpText.split(',');
			optionkeys = optionkeys.filter(function(x) {
				 return x !== '';
			});
		}
		const SortableItem = sortableElement(({value}) => {
			if(value != ''){
				let txt = value.split('|');
				return (<div id={txt[0]} className={'option-div'}><DragHandle /><div className="op-title">{txt[1]}</div><div className="op-val">{txt[2]}</div><div className="op-delete m-2"><button type="button" className="btn code-dialog btn-delete" onClick={()=>this.props.editOptionKey(value)}><i className="fa fa-edit"></i></button></div><div className="op-delete"><button type="button" style={{color:'red'}} className="btn code-dialog btn-delete" onClick={() => { if (window.confirm('Are you sure you want to Delete this option?')) that.props.deleteOptionKeyValue(value)}}><i className="fa fa-trash"></i></button></div>
				</div>);
			}
		});
		const SortableItem2 = sortableElement(({value}) => {
			if(value != ''){
				let txt = value.split('|');
				return (<div id={txt[0]} className={'option-div'}><DragHandle /><div className="op-title2">{txt[1]}</div><div className="op-delete m-2"><button type="button" className="btn code-dialog btn-delete" onClick={()=>this.props.editOptionKey2(value)}><i className="fa fa-edit"></i></button></div><div className="op-delete"><button type="button" style={{color:'red'}} className="btn code-dialog btn-delete" onClick={() => { if (window.confirm('Are you sure you want to Delete this option?')) that.props.deleteOptionKey(value)}}><i className="fa fa-trash"></i></button></div>
				</div>);
			}
		});
		const SortableContainer = sortableContainer(({children}) => {
			return <div className="option-row">{children}</div>;
		});
		let optionNumber = [1,2,3,4,5,6,7,8,9,10];
		let optionsHtml = delegateTypes.map(function(val,i) {
				let delegateName = val.split(':');
			return (
				<option value={val} key={i}>{delegateName[0]}</option>
			);
		})
		let logsHtml = statusLogs.map(function(val,i) {
			return (
				<li>{moment(val.date).format('MMDDYYYY HH:mm a')}: <b>{val.status}</b> assigned to {val.person} by {val.user}</li>
			);
		})
		let optionRank = trackerTableData.length;
		let rankOptions = props => {
			let content = [];
			if(!trackerRow){
				optionRank = Number(optionRank)+1
			}
			for(let i=1; i <= optionRank; i++ ){
				
				if(defaultProps.formRank == i ){
					content.push(
						<option key={i} value={i}>{i} (current)</option>
					);
				}else{
					content.push(
						<option key={i} value={i}>{i}</option>
					);
				}
			}
			return content;
		}
		let facilityOption = facilityIds.map(function(val,i) {
			let facility = val.split('|');
			if(facility[2] == 'original'){
				return (
					<option value={facility[1]} key={i}>{facility[0]}</option>
				);
			}
		})
		let gridRows = props => {
			let content = [];
			for(let i=1; i <= 20; i++ ){
				content.push(
					<option key={i} value={i}>{i}</option>
				);
			}
			return content;
		}
		let gridDropdown = props => {
			let content = [];
			for(let i=1; i <= 20; i++ ){
				content.push(
					<div className={"row-input row-"+i+" grid-dropdown"}>
						<label>Row {i} Text : </label>
						<DebounceInput debounceTimeout={2000} type="text" className="form-control" name={'R'+i+'C1'} onChange={getValue} value={this.props.defaultProps['R'+i+'C1']} />
						<div className='dropdown-col2'>
						<label>Row {i} Column 2 answer option : </label>
						<DebounceInput debounceTimeout={2000} type="text" className={"form-control r"+i+"c2"} name={'R'+i+'C2'} onChange={getValue} value={this.props.defaultProps['R'+i+'C2']} />
						{ i== 1 ? <span><input className="same-for-all" name="sameAllC2" type="checkbox" onChange={getValue} /> Same for all</span>
						:null}
						</div>
						<div className='dropdown-col3'>
						<label>Row {i} Column 3 answer option : </label>
						<DebounceInput debounceTimeout={2000} type="text" className={"form-control r"+i+"c3"} name={'R'+i+'C3'} onChange={getValue} value={this.props.defaultProps['R'+i+'C3']} /> 
						{ i== 1 ? <span><input className="same-for-all" name="sameAllC3" type="checkbox" onChange={getValue} /> Same for all</span>
						:null}
						</div>
					</div>
				);
			}
			return content;
		}
		let tagsHtml ='';
		if(defaultProps.ogTags){
			tagsHtml = defaultProps.ogTags.map(function(tag,i) {
				if(i > 0){
					return (
						<option value={tag} key={i}>{tag}</option>
					);
				}
            })
		}
		
		let rqFields = Object.fromEntries(defaultProps.requiredFields.entries());
		let selectedRq = [];
		for (let prop in rqFields) {
			selectedRq.push(rqFields[prop]);
		}
		selectedRq = selectedRq.filter(function(x) {
			 return x !== '';
		});
		
		let opFields = Object.fromEntries(defaultProps.optionalFields.entries());
		let selectedOp = [];
		for (let prop in opFields) {
			selectedOp.push(opFields[prop]);
		}
		selectedOp = selectedOp.filter(function(x) {
			 return x !== '';
		});
		
		return (
        <div className="build-details">
			
			{showBuildEdit && !showTrackerDetails ?
				<div className="build-edit-section">
					<div className="row">
						<div className="col-md-12">
							<div className="row-input">
								<label className="label-control"> 
								{dataMode == 'MDS' ?
								'Master Data Set Name'
								: dataMode == 'Child' ?
								'Default Layout name'
								:
								'Layout name'
								} </label>
								<br />
								<DebounceInput debounceTimeout={2000} id="mdsDataName" type="text" className="form-control build-select" name="mdsDataName" value={defaultProps.mdsDataName != 'Temp-File' ? defaultProps.mdsDataName : ''} onChange={getValue} />
							</div>
							{dataMode == 'Child' ?
								<div className="row-input">
									<label className="label-control">Friendly Name</label>
									<br />
									<DebounceInput debounceTimeout={2000} id="layoutFriendlyName" type="text" className="form-control build-select" name="layoutFriendlyName" value={defaultProps.layoutFriendlyName} onChange={getValue} />
								</div>
							:null}
							{dataMode == 'Child' ?
							<div className="row-input mds-div">
								<label className="label-control"> Form Category </label>
								<select id="layoutCategory" className="form-control mds-select build-select" name="layoutCategory" onChange={getValue} value={defaultProps.layoutCategory}>
									<option value="">Please Select</option>
									<option value="Administrative">Administrative</option>
									<option value="Clinical">Clinical</option>
									<option value="Fabrication">Fabrication</option>
									<option value="Purchasing">Purchasing</option>
									<option value="Patient">Patient</option>
									<option value="Prescription">Prescription</option>
									<option value="Insurance">Insurance</option>
									<option value="EDU-User">EDU-User</option>
									<option value="EDU-Patient">EDU-Patient</option>
								</select>
							</div>
							:null}
							
							{dataMode == 'Layout' ?
							<div className="row-input mds-div">
								<label className="label-control"> Layout Type </label>
								<select className="form-control mds-select build-select" name="layoutType" onChange={getValue} value={defaultProps.layoutType}>
									<option value="">Please Select</option>
									<option value="Clinical">Clinical</option>
									<option value="Fabrication">Fabrication</option>
									<option value="Admin">Admin</option>
									<option value="Workflow 1">Workflow 1</option>
									<option value="Workflow 2">Workflow 2</option>
									<option value="Workflow 3">Workflow 3</option>
									<option value="Workflow 4">Workflow 4</option>
									<option value="Workflow 5">Workflow 5</option>
								</select>
							</div>
							:null}
							
							<div className="row-input mds-div">
								<label className="label-control"> Facility ID </label>
								<select id="mdsFacility" className="form-control mds-select build-select" name="mdsFacility" onChange={getValue} value={defaultProps.mdsFacility}>
									<option value="">Please Select</option>
									{facilityOption}
								</select>
							</div>
							{/*dataMode != 'MDS' ?
							<div className="row-input multiss-div">
								<label className="label-control"> Required Groups </label>
								<select className="form-control custom-select build-select" name="mdsCompany" onChange={getValue} value={defaultProps.mdsCompanyArray} multiple>
									<option value="">Please Select</option>
									{companies.map((company, i) => (
										<option key={i} value={company}>{company}</option>
									))}
								</select>
							</div>
							:null}
							{dataMode != 'MDS' ?
							<div className="row-input multiss-div">
								<label className="label-control"> Filtering Groups </label>
								<select className="form-control custom-select build-select" name="mdsGroupId" onChange={getValue} value={defaultProps.mdsGroupIdArray} multiple>
									<option value="">Please Select</option>
									{groupId.map((group, i) => (
										<option key={i} value={group}>{group}</option>
									))}
								</select>
							</div>
							:null */}
							{dataMode == 'Child' ?
							<div className="row-input mds-div pfb">
								<label> Public: </label>
								<select className="form-control mds-select" value={this.props.defaultProps.Public} name="Public" onChange={getValue}>
									<option value="Yes">Yes</option>
									<option value="No">No</option>
								</select>
							</div>
							:null}
							{this.props.defaultProps.showCompany ?
							<div className="row-input private-layout">
								<label> Private Layout Owner: </label>
								<select className="form-control company-select-lay" value={this.props.defaultProps.privateOwner} name="privateOwner" onChange={getValue}>
									<option value="">Please Select</option>
									{companies.map((company, i) => (
										<option key={i} value={company}>{company}</option>
									))}
								</select>
							</div>
							:null}
							
							<div className="row-input multis-div">
								<label className="label-control"> All Delegate Types </label>
								<select id="allDelegate" className="form-control custom-select build-select" name="allDelegate" onChange={getValue} value={defaultProps.allDelegateArray} multiple>
									{optionsHtml}
								</select>
							</div>
							
							
							<div className="row-input multis-div">
								<label className="label-control">Associated Type (Form) </label>
								<select id="allTypes" className="form-control build-select associat-select" name="allTypes" onChange={this.getValue} value={defaultProps.allTypesArray} multiple>
									{types.map((val, i) => (
										<option key={i} value={val}>{val}</option>
									))}
								</select>
							</div>
							
							{dataMode == 'Child' ?
								<div className="row-input multis-div">
								<label className="label-control">Associated Anatomy</label>
								<select id="associatedAnatomy" className="form-control" name="associatedAnatomy" onChange={this.getValue} value={defaultProps.associatedAnatomy}>
									<option value="">Please Select</option>
									<option value='Upper'>Upper</option>
									<option value='Lower'>Lower</option>
									<option value='Cranial'>Cranial</option>
									<option value='Spinal'>Spinal</option>
									<option value='Mastectomy'>Mastectomy</option>
								</select>
							</div>
							:null}
							
							<div className="row-input multis-div">
								<label className="label-control">Associated Device Type (Form) </label>
								<select id="allDeviceTypes"  className="form-control custom-select build-select" name="allDeviceTypes" onChange={getValue} value={defaultProps.allDeviceTypesArray} multiple>
									{deviceTypes.map((val, i) => (
										<option key={i} value={val}>{val}</option>
									))}
								</select>
							</div>
							
							<div className="row-input multis-div">
								<label className="label-control">Associated Visit Type (Form) </label>
								<select id="allVisitTypes"  className="form-control custom-select build-select" name="allVisitTypes" onChange={getValue} value={defaultProps.allVisitTypesArray} multiple>
									{visitTypes.map((val, i) => (
										<option key={i} value={val}>{val}</option>
									))}
								</select>
							</div>
							
							{dataMode == 'Child' ?
							<div className="row-input multis-div aissgn-track">
								<label className="label-control"> Assigned Track </label> <button className="btn assign-btn" data-target="#assignTrack" data-toggle="modal">  + Add Track </button> 
								<select id="chooseTracking"  className="form-control track-select" value={defaultProps.chooseTrackingArray} name="chooseTracking" onChange={getValue} multiple>
									{assignTracks.map((code, i) => (
										<option key={i} value={code.trackName}>{code.trackName}</option>
									))}
								</select>
							</div>
							:null}
							
							{dataMode != 'Layout' ?
							<div className="row-input">
								<label className="label-control">Save As</label>
								<select id="saveAs" className="form-control save-as" name="saveAs" onChange={getValue} value={defaultProps.saveAs} >
									{dataMode == 'MDS' ?
									<option value='parentMds'>MDS</option>
									:
									<option value='childMds'>Default Layout</option>
									}
								</select>
							</div>
							:null}
							
							{dataMode == 'Child' ?
								<div className="row-input-check">
									<input id="inNote" className="btn code-dialog css-checkbox" name="internalNoteBox" type="checkbox" checked={defaultProps.internalNoteBox} onChange={getValue}/>
									<label htmlFor={"inNote"} className="css-label lite-green-check">Internal Notes</label>
								</div>
							:null}
							{this.props.defaultProps.internalNoteBox ?
							<div className="col-md-12 form-group">
								<DebounceInput element="textarea" debounceTimeout={4000} className="form-control" rows="4" value={this.props.defaultProps.internalNote} onChange={getValue} name="internalNote" />
							</div>
							:null}
							{dataMode == 'Child' ?
								<div className="row-input-check">
									<input id="startwork" className="btn code-dialog css-checkbox" name="startWorkflow" type="checkbox" checked={defaultProps.startWorkflow}  onChange={getValue}/>
									<label htmlFor={"startwork"} className="css-label lite-green-check">Start Workflow in Form Test</label>
								</div>
							:null}
							
							{this.props.defaultProps.startWorkflow ?
							<select className="form-control" name="workflowCategory" value={defaultProps.workflowCategory} onChange={getValue}>
									<option value={''}>Select Category</option>
										{categoryOption}
									</select>
							:null}
							{/*dataMode == 'MDS' && this.props.defaultProps.showPrefix?
							<div className="row-input1">
								<label className="label-control">Field Prefix</label>
								{loadDataSet && defaultProps.hasNotCopy ?
								<DebounceInput debounceTimeout={2000} type="text" className="form-control build-select" name="fieldPrefix" onChange={getValue} disabled value={this.props.defaultProps.fieldPrefix} />
								: <DebounceInput debounceTimeout={2000} type="text" className="form-control build-select" name="fieldPrefix" onChange={getValue} value={this.props.defaultProps.fieldPrefix}/>
								}
							</div>
							:null*/}
						</div>            
					</div>
				</div>
			:null}
			{showFieldEdit ?
				<div className="field-edit-section">
					<div className="row">
						{this.props.defaultProps.chooseAnsType == 'Child Header 1' ||  this.props.defaultProps.chooseAnsType == 'Child Header 2' || this.props.defaultProps.chooseAnsType == 'Child Header 3' ? (
							<div className="col-md-12 form-group" id="selectTypeDiv">
								<label> Choose Question Type: </label>
								<select className="form-control" name="chooseAnsType" value={this.props.defaultProps.chooseAnsType} onChange={getValue}>
									<option value="">Please Select</option>
									<option value="Child Header 1">Child Header 1</option>
									<option value="Child Header 2">Child Header 2</option>
									<option value="Child Header 3">Child Header 3</option>
								</select>
							</div>
						)
						:null}
						
						{this.props.defaultProps.changeQueType ? (
						<div className="col-md-12 form-group" id="selectTypeDiv">
							<label> Choose Question Type: </label>
							<select className="form-control" id="selectType" name="chooseAnsType" value={this.props.defaultProps.chooseAnsType.toLowerCase()} onChange={getValue}>
								<option value="">Please Select</option>
								<option value="q1">Q1-Predefined list of options</option>
								<option value="q2">Q2-User Defined Dropdown Menu (B+)</option>
								<option value="q3">Q3-Multi-select Dropdown</option>
								<option value="q4">Q4-Radio Buttons</option>
								<option value="q5">Q5-Single Checkbox</option>
								<option value="q6">Q6-Small Blank Field</option>
								<option value="q7">Q7-Large Blank Field</option>
								<option value="q8">Q8-Date Selection</option>
								<option value="q9">Q9-Extra Large Text Box</option>
								<option value="q10">Q10-Single Check Box Plus Details</option>
								<option value="q11">Q11-Multiple checkboxes select</option>
								<option value="q11b">Q11 b-Multiple checkboxes+</option>
								<option value="q12">Radio Button +</option>
								<option value="q13">One-line Radio Button +</option>
								<option value="q14">List Of Options (Keyed)</option>
								<option value="title">Title</option>
								<option value="text">Text</option>
								<option value="timer">Timer</option>
								<option value="slider">Slider</option>
								<option value="image">Image</option>
								<option value="video">Video Upload</option>
								<option value="apivideo">API Video Upload</option>
								<option value="file">File Upload</option>
								<option value="obj">Obj Upload</option>
								<option value="sig">Sig</option>
								<option value="hidden">Hidden Dimensions</option>
								<option value="calculation">Calculation</option>
								<option value="subjective">Subjective</option>
								<option value="assessment">Assessment</option>
								<option value="reduction">Reduction Values</option>
								<option value="pdfapi">Get PDF via API</option>
								<option value="link">Link</option>
								{/* <option value="boxbegin">Box-Begin</option>
								<option value="boxend">Box-End</option> */}
								<option value="inlinevideo">Inline Video</option>
								<option value="basicgrid">Basic Grid 1</option>
								<option value="basicgrid2">Basic Grid 2</option>
								<option value="grid5point">Grid- 5 Points</option>
								<option value="gridcheck">Grid-check</option>
								<option value="likert">Likert</option>
								<option value="smart">Smart Multi-select</option>
								<option value="weighted">Weighted Score Grid</option>
								<option value="wcalculation">Weighted Value Calculations</option>
								<option value="grid-dropdown">Grid Drop Down</option>
								<option value="launchpad">Launch Pad</option>
								<option value="grid-multicheck">Multi-Check-Row</option>
								<option value="grid-singlecheck">Single-Check-Row</option>
								<option value="checklist">Create Checklist</option>
							</select>
						</div>)
						:null}
						
						{this.props.defaultProps.changeWebsite ?
						<div className="col-md-12 form-group" id='linkType'>
							<label> Website address: </label>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="websiteLink" value={this.props.defaultProps.websiteLink} onChange={getValue} placeholder="http://"/>
						</div>
						:null}
						
						<div className="col-md-12 form-group" id='qtext'>
							<label> Visible Text: </label>
							
							<DebounceInput element="textarea" debounceTimeout={4000} className="form-control" rows="2" value={this.props.defaultProps.changeQueInpText} onChange={getValue} name="changeQueInpText" />
						</div>
						
						{this.props.defaultProps.changeLaunchpad ?
						<div className="launch-pad">
							<div className="col-md-12 form-group">
								<label> URL: </label>
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="websiteLink" value={this.props.defaultProps.websiteLink} onChange={getValue} placeholder="https://"/>
							</div>
							
							<div className="col-md-12 form-group">
								<label> Response Action: </label>
								<select className="form-control build-select" name="responseAction" onChange={getValue} value={defaultProps.responseAction}>
									<option value='None'>None</option>
									<option value='Window'>Window</option>
									<option value='iFrame'>iFrame</option>
								</select>
							</div>
							
							<div className="col-md-12 form-group">
								<label> OG Auto Tags: </label>
								<select className="form-control build-select ogtags-select" name="ogAutoTags" onChange={getValue} value={defaultProps.ogAutoTagsArray} multiple>
									{tagsHtml}
								</select>
							</div>
							
							<div className="col-md-12 form-group">
								<label> MDS Sheets: </label>
								<select className="form-control build-select" name="launchSelectedSheet" onChange={this.props.getRqAndOpFields} value={defaultProps.launchpadMds}>
									<option value=''>Select Sheet</option>
									{defaultProps.mdsSheets.map((val, i) => (
										<option key={i} value={val}>{val}</option>
									))}
								</select>
							</div>
							
							<div className="col-md-12 form-group">
								<label> Required Fields: <b>Selector</b> </label>
								<div className="launch-table-div">
								<table className="table table-bordered launch-table">
								<tbody>
								{defaultProps.rqFields.map((val,i) =>{
									let requiredFields = defaultProps.requiredFields;
									let fieldText = encodeURIComponent(val[1]);
									let hasId = requiredFields.get(val[3]+'|'+val[2]+'|'+fieldText);
									return (<tr key={i}>
										<td><input name={val[3]+'|'+val[2]+'|'+fieldText} type="checkbox" value="rq" onChange={this.props.handleLaunchPadItem} checked={hasId ? 'checked' : ''} /></td>
										<td>{val[1]}</td>
									</tr>)
								})}
								</tbody>
								</table>
								</div>
							</div>
							
							<div className="col-md-12 form-group">
								<label> Selected Required Fields</label>
								
								<div className="launch-table-div">
								<table className="table table-bordered launch-table">
								<tbody>
								{selectedRq.map((filed,i) =>{
									let reqFields = filed.split('|');
									let fieldText = decodeURIComponent(reqFields[2]);
									return (<tr key={i}>
										<td><input name={filed} type="checkbox" value="rq" onChange={this.props.handleLaunchPadItem} checked='checked'/></td>
										<td>{fieldText}</td>
									</tr>)
								})}
								</tbody>
								</table>
								</div>
							</div>
							
							<div className="col-md-12 form-group">
								<label> Optional Fields: <b>Selector</b></label>
								<div className="launch-table-div">
								<table className="table table-bordered launch-table">
								<tbody>
								{defaultProps.opFields.map((val,i) =>{
									let optionFields = defaultProps.optionalFields;
									let fieldText = encodeURIComponent(val[1]);
									let hasId = optionFields.get(val[3]+'|'+val[2]+'|'+fieldText);
									return (<tr key={i}>
										<td><input name={val[3]+'|'+val[2]+'|'+fieldText} type="checkbox" value="op" onChange={this.props.handleLaunchPadItem} checked={hasId ? 'checked' : ''} /></td>
										<td>{val[1]}</td>
									</tr>)
								})}
								</tbody>
								</table>
								</div>
							</div>
							
							<div className="col-md-12 form-group">
								<label> Selected Optional Fields</label>
								
								<div className="launch-table-div">
								<table className="table table-bordered launch-table">
								<tbody>
								{selectedOp.map((filed,i) =>{
									let opFields = filed.split('|');
									let fieldText = decodeURIComponent(opFields[2]);
									return (<tr key={i}>
										<td><input name={filed} type="checkbox" value="op" onChange={this.props.handleLaunchPadItem} checked='checked'/></td>
										<td>{fieldText}</td>
									</tr>)
								})}
								</tbody>
								</table>
								</div>
							</div>
							
							<div className="col-md-12 form-group" >
								<label> Launch Pad type: </label>
								<select className="form-control" name="launchpadType" value={this.props.defaultProps.launchpadType} onChange={getValue}>
									<option value="LP1">LP1</option>
									<option value="LP2">LP2</option>
									<option value="LP3">LP3</option>
								</select>
							</div>
							
							{/*<div className="col-md-12 form-group">
								<label> Related Tags </label>
								<input type="radio" name="tag" /> Auto tags <input type="radio" name="tag" /> Required tags
							</div>
							<div className="col-md-12 form-group">
								<textarea  className="form-control" name="tags" onChange={getValue} ></textarea>
						</div>*/}
							<div className="col-md-12 form-group">
								<label> Button Label: </label>
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="buttonLabel" value={this.props.defaultProps.buttonLabel} onChange={getValue} />
							</div>
							
						</div>
						
						:null}
						
						{this.props.defaultProps.changeCalculation ?
						<div className="col-md-12 form-group">
							<div className="form-group" id='calculationType' >
								<label> Calculation type: </label>
								<select className="form-control" name="calculationType" value={this.props.defaultProps.calculationType} onChange={getValue}>
									<option value="">Please Select</option>
									<option value="Sum">Sum</option>
									<option value="Average">Average</option>
									<option value="Subtract">Subtract A-B</option>
									<option value="HighestNumber">Highest Number</option>
									<option value="LowestNumber">Lowest Number</option>
									<option value="LongestTime">Longest Time</option>
									<option value="ShortestTime">Shortest Time</option>
									
								</select>
							</div>
							<div className="form-group">
								<label>Field Name's filter by type:</label>
								<select className="form-control" name="validationTypefilter" onChange={getValue}>
										<option value="">Please Select</option>
										<option value="V3">V3 Whole +Numbers and Zero</option>
										<option value="V4">V4 Whole +Numbers no Zero</option>
										<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
										<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
										<option value="V7">V7 Percentage Positive Only</option>
										<option value="V8">V8 Fractions Positive Only</option>
										<option value="V12">V12	Time hh:mm:ss:ssss</option>
										<option value="V13">V13 Time ss:ssss</option>
										<option value="V14">V14 Whole -Numbers and no Zero</option>
									</select>
								<br />	
								<label> Select the Field Name's to include in the calculation:</label>
								{ this.props.calculationFields() } 
							</div>
						</div>
						:null}
						
						{this.props.defaultProps.changeGridOption ?
						<div className="col-md-12 form-group" id='basicgrid' >
							
							<label> Number of data Columns </label>
							 
							<select className="form-control number-rows form-group basicgrid-col small-width" value={this.props.defaultProps.numberOfColumn} name="numberOfColumn" onChange={getValue}>
								<option value='1'>1</option>
								<option value='2'>2</option>
								<option value='3'>3</option>
								<option value='4'>4</option>
								<option value='5'>5</option>
							</select>
							
							<div className="column-input column-1">
								<b>Data Column 1 : </b>
								<br />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C1} name="C1" onChange={getValue} />
							</div>
							
							<div className="column-input column-2" style={{display:'none'}}>
								<b>Data Column 2 : </b>
								<br />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C2} name="C2" onChange={getValue} maxLength={50} />
							</div>
							<div className="column-input column-3" style={{display:'none'}}>
								<b>Data Column 3 : </b>
								<br />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C3} name="C3" onChange={getValue} maxLength={50} />
							</div>
							<div className="column-input column-4" style={{display:'none'}}>
								<b>Data Column 4 : </b>
								<br />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C4} name="C4" onChange={getValue} maxLength={50} />
							</div>
							<div className="column-input column-5" style={{display:'none'}}>
								<b>Data Column 5 : </b>
								<br />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C5} name="C5" onChange={getValue} maxLength={50} />
							</div>
							<br />
							
							<label> Number of Rows </label>
							 
							<select className="form-control form-group number-rows small-width" value={this.props.defaultProps.numberRows} name="numberRows" onChange={getValue}>
								{gridRows()}
							</select>
							
							<div className="row-input row-1"><b>Field name 1 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R1" onChange={getValue} maxLength={75} value={this.props.defaultProps.R1} /></div>
							<div className="row-input row-2" style={{display:'none'}}><b>Field name 2 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R2" onChange={getValue} maxLength={75} value={this.props.defaultProps.R2} /></div>
							<div className="row-input row-3" style={{display:'none'}}><b>Field name 3 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R3" onChange={getValue} maxLength={75} value={this.props.defaultProps.R3} /></div>
							<div className="row-input row-4" style={{display:'none'}}><b>Field name 4 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R4" onChange={getValue} maxLength={75} value={this.props.defaultProps.R4} /></div>
							<div className="row-input row-5" style={{display:'none'}}><b>Field name 5 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R5" onChange={getValue} maxLength={75} value={this.props.defaultProps.R5} /></div>
							<div className="row-input row-6" style={{display:'none'}}><b>Field name 6 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R6" onChange={getValue} maxLength={75} value={this.props.defaultProps.R6} /></div>
							<div className="row-input row-7" style={{display:'none'}}><b>Field name 7 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R7" onChange={getValue} maxLength={75} value={this.props.defaultProps.R7} /></div>
							<div className="row-input row-8" style={{display:'none'}}><b>Field name 8 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R8" onChange={getValue} maxLength={75} value={this.props.defaultProps.R8} /></div>
							<div className="row-input row-9" style={{display:'none'}}><b>Field name 9 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R9" onChange={getValue} maxLength={75} value={this.props.defaultProps.R9}/></div>
							<div className="row-input row-10" style={{display:'none'}}><b>Field name 10 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R10" onChange={getValue} maxLength={75} value={this.props.defaultProps.R10}/></div>
							<div className="row-input row-11" style={{display:'none'}}><b>Field name 11 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R11" onChange={getValue} maxLength={75} value={this.props.defaultProps.R11}/></div>
							<div className="row-input row-12" style={{display:'none'}}><b>Field name 12 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R12" onChange={getValue} maxLength={75} value={this.props.defaultProps.R12}/></div>
							<div className="row-input row-13" style={{display:'none'}}><b>Field name 13 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R13" onChange={getValue} maxLength={75} value={this.props.defaultProps.R13}/></div>
							<div className="row-input row-14" style={{display:'none'}}><b>Field name 14 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R14" onChange={getValue} maxLength={75} value={this.props.defaultProps.R14}/></div>
							<div className="row-input row-15" style={{display:'none'}}><b>Field name 15 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R15" onChange={getValue} maxLength={75} value={this.props.defaultProps.R15}/></div>
							
							<div className="row-input row-16" style={{display:'none'}}><b>Field name 16 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R16" onChange={getValue} maxLength={75} value={this.props.defaultProps.R16}/></div>
							<div className="row-input row-17" style={{display:'none'}}><b>Field name 17 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R17" onChange={getValue} maxLength={75} value={this.props.defaultProps.R17}/></div>
							<div className="row-input row-18" style={{display:'none'}}><b>Field name 18 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R18" onChange={getValue} maxLength={75} value={this.props.defaultProps.R18}/></div>
							<div className="row-input row-19" style={{display:'none'}}><b>Field name 19 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R19" onChange={getValue} maxLength={75} value={this.props.defaultProps.R19}/></div>
							<div className="row-input row-20" style={{display:'none'}}><b>Field name 20 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R20" onChange={getValue} maxLength={75} value={this.props.defaultProps.R20}/></div>
							
							<br />
						</div>
						:null}
						
						{this.props.defaultProps.changeBasicGrid ?
						<div className="col-md-12 form-group" id='basicgrid' >
							
							<label> Number of data Columns </label>
							 
							<select className="form-control number-rows form-group basicgrid-col small-width" value={this.props.defaultProps.numberOfColumn} name="numberOfColumn" onChange={getValue}>
								<option value='1'>1</option>
								<option value='2'>2</option>
								<option value='3'>3</option>
								<option value='4'>4</option>
								<option value='5'>5</option>
								<option value='6'>6</option>
								<option value='7'>7</option>
								<option value='8'>8</option>
							</select>
							
							<div className="column-input column-1">
								<b>Data Column 1 : </b>
								<br />
								
								{this.props.defaultProps.changeBasicGrid2 ?
									<select className="form-control number-rows form-group basicgrid-col" value={this.props.defaultProps.columnOption1} name="columnOption1" onChange={getValue}>
										<option value='free'>Free Text</option>
										<option value='limited'>Limited Options</option>
									</select>
								:null}
								
								{this.props.defaultProps.limited1 ?
									<div>
									<label><b>Data column header</b></label>
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.C1} name="C1" onChange={getValue} />
									
									<b>Column 1 answer options: </b>
									<br />
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.Option1} name="Option1" onChange={getValue} /></div>
								:
									<span>
									<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C1} name="C1" onChange={getValue} />
									<select className="form-control custom-validation" value={this.props.defaultProps.validation1} name="validation1" onChange={getValue}>
										<option value="V1">V1 Text</option>
										<option value="V2">V2 Text Alphabet Only (no numbers)</option>
										<option value="V3">V3 Whole +Numbers and Zero</option>
										<option value="V4">V4 Whole +Numbers no Zero</option>
										<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
										<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
										<option value="V9">V9 Dates Past</option>
										<option value="V10">V10	Dates Today Only</option>
										<option value="V11">V11	Dates Future</option>
										<option value="V12">V12	Time hh:mm:ss:ssss</option>
									</select>
									{this.props.defaultProps.validation1 != 'V10' ?
										<DebounceInput debounceTimeout={2000} type="text" className="form-control wwww v-min vmin1" value={this.props.defaultProps.vmin1} name="vmin1" onChange={getValue} placeholder="min value"/>
									:null}
									{this.props.defaultProps.validation1 != 'V10' ?
										<DebounceInput debounceTimeout={2000} type="text" className="form-control www v-max vmax1" value={this.props.defaultProps.vmax1} name="vmax1" onChange={getValue} placeholder="max value"/>
									:null}
									
									</span>
								}
							</div>
							
							<div className="column-input column-2" style={{display:'none'}}>
								<b>Data Column 2 : </b>
								<br />
								
								{this.props.defaultProps.changeBasicGrid2 ?
									<select className="form-control number-rows form-group basicgrid-col" value={this.props.defaultProps.columnOption2} name="columnOption2" onChange={getValue}>
										<option value='free'>Free Text</option>
										<option value='limited'>Limited Options</option>
									</select>
								:null}
								
								{this.props.defaultProps.limited2 ?
									<div>
									<label><b>Data column header</b></label>
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.C2} name="C2" onChange={getValue} />
									<b>Column 2 answer options: </b>
									<br />
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.Option2} name="Option2" onChange={getValue} /></div>
								:
									<span><DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C2} name="C2" onChange={getValue} maxLength={50} />
									<select className="form-control custom-validation" value={this.props.defaultProps.validation2} name="validation2" onChange={getValue}>
										<option value="V1">V1 Text</option>
										<option value="V2">V2 Text Alphabet Only (no numbers)</option>
										<option value="V3">V3 Whole +Numbers and Zero</option>
										<option value="V4">V4 Whole +Numbers no Zero</option>
										<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
										<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
										<option value="V9">V9 Dates Past</option>
										<option value="V10">V10	Dates Today Only</option>
										<option value="V11">V11	Dates Future</option>
										<option value="V12">V12	Time hh:mm:ss:ssss</option>
									</select>
									{this.props.defaultProps.validation2 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-min vmin2" value={this.props.defaultProps.vmin2} name="vmin2" onChange={getValue} placeholder="min value"/>
									:null}
									{this.props.defaultProps.validation2 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-max vmax2" value={this.props.defaultProps.vmax2} name="vmax2" onChange={getValue} placeholder="max value"/>
									:null}
									</span>
								}
							</div>
							<div className="column-input column-3" style={{display:'none'}}>
								<b>Data Column 3 : </b>
								<br />
								
								{this.props.defaultProps.changeBasicGrid2 ?
									<select className="form-control number-rows form-group basicgrid-col" value={this.props.defaultProps.columnOption3} name="columnOption3" onChange={getValue}>
										<option value='free'>Free Text</option>
										<option value='limited'>Limited Options</option>
									</select>
								:null}
								
								{this.props.defaultProps.limited3 ?
									<div>
									<label><b>Data column header</b></label>
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.C3} name="C3" onChange={getValue} />
									<b>Column 3 answer options: </b>
									<br />
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.Option3} name="Option3" onChange={getValue} /></div>
								:
									<span><DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C3} name="C3" onChange={getValue} maxLength={50} />
									<select className="form-control custom-validation" value={this.props.defaultProps.validation3} name="validation3" onChange={getValue}>
										<option value="V1">V1 Text</option>
										<option value="V2">V2 Text Alphabet Only (no numbers)</option>
										<option value="V3">V3 Whole +Numbers and Zero</option>
										<option value="V4">V4 Whole +Numbers no Zero</option>
										<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
										<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
										<option value="V9">V9 Dates Past</option>
										<option value="V10">V10	Dates Today Only</option>
										<option value="V11">V11	Dates Future</option>
										<option value="V12">V12	Time hh:mm:ss:ssss</option>
									</select>
									{this.props.defaultProps.validation3 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-min vmin3" value={this.props.defaultProps.vmin3} name="vmin3" onChange={getValue} placeholder="min value"/>
									:null}
									{this.props.defaultProps.validation3 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-max vmax3" value={this.props.defaultProps.vmax3} name="vmax3" onChange={getValue} placeholder="max value"/>
									:null}
									</span>
								}
							</div>
							<div className="column-input column-4" style={{display:'none'}}>
								<b>Data Column 4 : </b>
								<br />
								
								{this.props.defaultProps.changeBasicGrid2 ?
									<select className="form-control number-rows form-group basicgrid-col" value={this.props.defaultProps.columnOption4} name="columnOption4" onChange={getValue}>
										<option value='free'>Free Text</option>
										<option value='limited'>Limited Options</option>
									</select>
								:null}
								
								{this.props.defaultProps.limited4 ?
									<div>
									<label><b>Data column header</b></label>
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.C4} name="C4" onChange={getValue} />
									<b>Column 4 answer options: </b>
									<br />
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.Option4} name="Option4" onChange={getValue} /></div>
								:
									<span><DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C4} name="C4" onChange={getValue} maxLength={50} />
									<select className="form-control custom-validation" value={this.props.defaultProps.validation4} name="validation4" onChange={getValue}>
										<option value="V1">V1 Text</option>
										<option value="V2">V2 Text Alphabet Only (no numbers)</option>
										<option value="V3">V3 Whole +Numbers and Zero</option>
										<option value="V4">V4 Whole +Numbers no Zero</option>
										<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
										<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
										<option value="V9">V9 Dates Past</option>
										<option value="V10">V10	Dates Today Only</option>
										<option value="V11">V11	Dates Future</option>
										<option value="V12">V12	Time hh:mm:ss:ssss</option>
									</select>
									{this.props.defaultProps.validation4 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-min vmin4" value={this.props.defaultProps.vmin4} name="vmin4" onChange={getValue} placeholder="min value"/>
									:null}
									{this.props.defaultProps.validation4 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-max vmax4" value={this.props.defaultProps.vmax4} name="vmax4" onChange={getValue} placeholder="max value"/>
									:null}
									</span>
								}
							</div>
							<div className="column-input column-5" style={{display:'none'}}>
								<b>Data Column 5 : </b>
								<br />
								
								{this.props.defaultProps.changeBasicGrid2 ?
									<select className="form-control number-rows form-group basicgrid-col" value={this.props.defaultProps.columnOption5} name="columnOption5" onChange={getValue}>
										<option value='free'>Free Text</option>
										<option value='limited'>Limited Options</option>
									</select>
								:null}
								
								{this.props.defaultProps.limited5 ?
									<div>
									<label><b>Data column header</b></label>
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.C5} name="C5" onChange={getValue} />
									<b>Column 5 answer options: </b>
									<br />
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.Option5} name="Option5" onChange={getValue} /></div>
								:
									<span><DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C5} name="C5" onChange={getValue} maxLength={50} />
									<select className="form-control custom-validation" value={this.props.defaultProps.validation5} name="validation5" onChange={getValue}>
										<option value="V1">V1 Text</option>
										<option value="V2">V2 Text Alphabet Only (no numbers)</option>
										<option value="V3">V3 Whole +Numbers and Zero</option>
										<option value="V4">V4 Whole +Numbers no Zero</option>
										<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
										<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
										<option value="V9">V9 Dates Past</option>
										<option value="V10">V10	Dates Today Only</option>
										<option value="V11">V11	Dates Future</option>
										<option value="V12">V12	Time hh:mm:ss:ssss</option>
									</select>
									{this.props.defaultProps.validation5 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-min vmin5" value={this.props.defaultProps.vmin5} name="vmin5" onChange={getValue} placeholder="min value"/>
									:null}
									{this.props.defaultProps.validation5 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-max vmax5" value={this.props.defaultProps.vmax5} name="vmax5" onChange={getValue} placeholder="max value"/>
									:null}
									</span>
								}
							</div>
							<div className="column-input column-6" style={{display:'none'}}>
								<b>Data Column 6 : </b>
								<br />
								
								{this.props.defaultProps.changeBasicGrid2 ?
									<select className="form-control number-rows form-group basicgrid-col" value={this.props.defaultProps.columnOption6} name="columnOption6" onChange={getValue}>
										<option value='free'>Free Text</option>
										<option value='limited'>Limited Options</option>
									</select>
								:null}
								
								{this.props.defaultProps.limited6 ?
									<div>
									<label><b>Data column header</b></label>
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.C6} name="C6" onChange={getValue} />
									<b>Column 6 answer options: </b>
									<br />
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.Option6} name="Option6" onChange={getValue} /></div>
								:
									<span><DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C6} name="C6" onChange={getValue} maxLength={50} />
									<select className="form-control custom-validation" value={this.props.defaultProps.validation6} name="validation6" onChange={getValue}>
										<option value="V1">V1 Text</option>
										<option value="V2">V2 Text Alphabet Only (no numbers)</option>
										<option value="V3">V3 Whole +Numbers and Zero</option>
										<option value="V4">V4 Whole +Numbers no Zero</option>
										<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
										<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
										<option value="V9">V9 Dates Past</option>
										<option value="V10">V10	Dates Today Only</option>
										<option value="V11">V11	Dates Future</option>
										<option value="V12">V12	Time hh:mm:ss:ssss</option>
									</select>
									{this.props.defaultProps.validation6 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-min vmin6" value={this.props.defaultProps.vmin6} name="vmin6" onChange={getValue} placeholder="min value"/>
									:null}
									{this.props.defaultProps.validation6 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-max vmax6" value={this.props.defaultProps.vmax6} name="vmax6" onChange={getValue} placeholder="max value"/>
									:null}
									</span>
								}
							</div>
							<div className="column-input column-7" style={{display:'none'}}>
								<b>Data Column 7 : </b>
								<br />
								
								{this.props.defaultProps.changeBasicGrid2 ?
									<select className="form-control number-rows form-group basicgrid-col" value={this.props.defaultProps.columnOption7} name="columnOption7" onChange={getValue}>
										<option value='free'>Free Text</option>
										<option value='limited'>Limited Options</option>
									</select>
								:null}
								
								{this.props.defaultProps.limited7 ?
									<div>
									<label><b>Data column header</b></label>
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.C7} name="C7" onChange={getValue} />
									<b>Column 7 answer options: </b>
									<br />
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.Option7} name="Option7" onChange={getValue} /></div>
								:
									<span><DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C7} name="C7" onChange={getValue} maxLength={50} />
									<select className="form-control custom-validation" value={this.props.defaultProps.validation7} name="validation7" onChange={getValue}>
										<option value="V1">V1 Text</option>
										<option value="V2">V2 Text Alphabet Only (no numbers)</option>
										<option value="V3">V3 Whole +Numbers and Zero</option>
										<option value="V4">V4 Whole +Numbers no Zero</option>
										<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
										<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
										<option value="V9">V9 Dates Past</option>
										<option value="V10">V10	Dates Today Only</option>
										<option value="V11">V11	Dates Future</option>
										<option value="V12">V12	Time hh:mm:ss:ssss</option>
									</select>
									{this.props.defaultProps.validation7 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-min vmin7" value={this.props.defaultProps.vmin7} name="vmin7" onChange={getValue} placeholder="min value"/>
									:null}
									{this.props.defaultProps.validation7 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-max vmax7" value={this.props.defaultProps.vmax7} name="vmax7" onChange={getValue} placeholder="max value"/>
									:null}
									</span>
								}
							</div>
							<div className="column-input column-8" style={{display:'none'}}>
								<b>Data Column 8 : </b>
								<br />
								
								{this.props.defaultProps.changeBasicGrid2 ?
									<select className="form-control number-rows form-group basicgrid-col" value={this.props.defaultProps.columnOption8} name="columnOption8" onChange={getValue}>
										<option value='free'>Free Text</option>
										<option value='limited'>Limited Options</option>
									</select>
								:null}
								
								{this.props.defaultProps.limited8 ?
									<div>
									<label><b>Data column header</b></label>
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.C8} name="C8" onChange={getValue} />
									<b>Column 8 answer options: </b>
									<br />
									<DebounceInput debounceTimeout={2000} type="text" className="form-control grid-option-input" value={this.props.defaultProps.Option8} name="Option8" onChange={getValue} /></div>
								:
									<span><DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C8} name="C8" onChange={getValue} maxLength={50} />
									<select className="form-control custom-validation" value={this.props.defaultProps.validation8} name="validation8" onChange={getValue}>
										<option value="V1">V1 Text</option>
										<option value="V2">V2 Text Alphabet Only (no numbers)</option>
										<option value="V3">V3 Whole +Numbers and Zero</option>
										<option value="V4">V4 Whole +Numbers no Zero</option>
										<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
										<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
										<option value="V9">V9 Dates Past</option>
										<option value="V10">V10	Dates Today Only</option>
										<option value="V11">V11	Dates Future</option>
										<option value="V12">V12	Time hh:mm:ss:ssss</option>
									</select>
									{this.props.defaultProps.validation8 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-min vmin8" value={this.props.defaultProps.vmin8} name="vmin8" onChange={getValue} placeholder="min value"/>
									:null}
									{this.props.defaultProps.validation8 != 'V10' ?
									<DebounceInput debounceTimeout={2000} type="text" className="form-control v-max vmax8" value={this.props.defaultProps.vmax8} name="vmax8" onChange={getValue} placeholder="max value"/>
									:null}
									</span>
								}
							</div>
							<br />
							
							<label> Number of Rows </label>
							 
							<select className="form-control form-group number-rows small-width" value={this.props.defaultProps.numberRows} name="numberRows" onChange={getValue}>
								{gridRows()}
							</select>
							
							<div className="row-input row-1"><b>Field name 1 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R1" onChange={getValue} maxLength={50} value={this.props.defaultProps.R1} /></div>
							<div className="row-input row-2" style={{display:'none'}}><b>Field name 2 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R2" onChange={getValue} maxLength={50} value={this.props.defaultProps.R2} /></div>
							<div className="row-input row-3" style={{display:'none'}}><b>Field name 3 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R3" onChange={getValue} maxLength={50} value={this.props.defaultProps.R3} /></div>
							<div className="row-input row-4" style={{display:'none'}}><b>Field name 4 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R4" onChange={getValue} maxLength={50} value={this.props.defaultProps.R4} /></div>
							<div className="row-input row-5" style={{display:'none'}}><b>Field name 5 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R5" onChange={getValue} maxLength={50} value={this.props.defaultProps.R5} /></div>
							<div className="row-input row-6" style={{display:'none'}}><b>Field name 6 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R6" onChange={getValue} maxLength={50} value={this.props.defaultProps.R6} /></div>
							<div className="row-input row-7" style={{display:'none'}}><b>Field name 7 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R7" onChange={getValue} maxLength={50} value={this.props.defaultProps.R7} /></div>
							<div className="row-input row-8" style={{display:'none'}}><b>Field name 8 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R8" onChange={getValue} maxLength={50} value={this.props.defaultProps.R8} /></div>
							<div className="row-input row-9" style={{display:'none'}}><b>Field name 9 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R9" onChange={getValue} maxLength={50} value={this.props.defaultProps.R9}/></div>
							<div className="row-input row-10" style={{display:'none'}}><b>Field name 10 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R10" onChange={getValue} maxLength={50} value={this.props.defaultProps.R10}/></div>
							<div className="row-input row-11" style={{display:'none'}}><b>Field name 11 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R11" onChange={getValue} maxLength={50} value={this.props.defaultProps.R11}/></div>
							<div className="row-input row-12" style={{display:'none'}}><b>Field name 12 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R12" onChange={getValue} maxLength={50} value={this.props.defaultProps.R12}/></div>
							<div className="row-input row-13" style={{display:'none'}}><b>Field name 13 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R13" onChange={getValue} maxLength={50} value={this.props.defaultProps.R13}/></div>
							<div className="row-input row-14" style={{display:'none'}}><b>Field name 14 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R14" onChange={getValue} maxLength={50} value={this.props.defaultProps.R14}/></div>
							<div className="row-input row-15" style={{display:'none'}}><b>Field name 15 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R15" onChange={getValue} maxLength={50} value={this.props.defaultProps.R15}/></div>
							
							<div className="row-input row-16" style={{display:'none'}}><b>Field name 16 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R16" onChange={getValue} maxLength={50} value={this.props.defaultProps.R16}/></div>
							<div className="row-input row-17" style={{display:'none'}}><b>Field name 17 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R17" onChange={getValue} maxLength={50} value={this.props.defaultProps.R17}/></div>
							<div className="row-input row-18" style={{display:'none'}}><b>Field name 18 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R18" onChange={getValue} maxLength={50} value={this.props.defaultProps.R18}/></div>
							<div className="row-input row-19" style={{display:'none'}}><b>Field name 19 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R19" onChange={getValue} maxLength={50} value={this.props.defaultProps.R19}/></div>
							<div className="row-input row-20" style={{display:'none'}}><b>Field name 20 : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="R20" onChange={getValue} maxLength={50} value={this.props.defaultProps.R20}/></div>
							<br />
						</div>
						:null}
						
						{this.props.defaultProps.changeGridDropdown ?
						<div className="col-md-12 form-group" id='basicgrid' >
							
							<label> Number of data Columns </label>
							 
							<select className="form-control number-rows form-group basicgrid-col small-width" value={this.props.defaultProps.numberOfColumn} name="numberOfColumn" onChange={getValue}>
								<option value='2'>2</option>
								<option value='3'>3</option>
							</select>
							
							<div className="column-input column-1">
								<b>Data Column 1 : </b>
								<br />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C1} name="C1" onChange={getValue} />
								
							</div>
							
							<div className="column-input column-2">
								<b>Data Column 2 : </b>
								<br />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C2} name="C2" onChange={getValue} maxLength={50} />
								
							</div>
							<div className="column-input column-3" style={{display:'none'}}>
								<b>Data Column 3 : </b>
								<br />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.C3} name="C3" onChange={getValue} maxLength={50} />
								
							</div>
							<br />
							
							<label> Number of Rows </label>
							 
							<select className="form-control form-group number-rows small-width" value={this.props.defaultProps.numberRows} name="numberRows" onChange={getValue}>
								{gridRows()}
							</select>
							{gridDropdown()}
							
						</div>
						:null}
						
						{this.props.defaultProps.changeGridCheck ?
						<div className="col-md-6 form-group" id='gridcheck'>
							<label> Number of Grid Columns </label>
							<select className="form-control number-rows small-width" name="numberOfGrid" value={this.props.defaultProps.numberOfGrid} onChange={getValue}>
								<option value='2'>2</option>
								<option value='3'>3</option>
								<option value='4'>4</option>
							</select>
						</div>
						:null}
						
						{this.props.defaultProps.changeGrid5 ?
						<div className="col-md-12 form-group" id='grid5point'>
							<label> 5 Scale Headers</label>
							<div className="grid-sacale">
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="sacale1" value={this.props.defaultProps.sacale1} onChange={getValue} maxLength={50} />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="sacale2" value={this.props.defaultProps.sacale2} onChange={getValue} maxLength={50} />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="sacale3" value={this.props.defaultProps.sacale3} onChange={getValue} maxLength={50} />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="sacale4" value={this.props.defaultProps.sacale4} onChange={getValue} maxLength={50} />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="sacale5" value={this.props.defaultProps.sacale5} onChange={getValue} maxLength={50} />
							</div>
							
							<label> Number of Rows </label>
							 
							<select className="form-control number-rows form-group small-width" value={this.props.defaultProps.numberRows} name="numberRows" onChange={this.getValue}>
								<option value='1'>1</option>
								<option value='2'>2</option>
								<option value='3'>3</option>
								<option value='4'>4</option>
								<option value='5'>5</option>
							</select>
							
							<div className="row-input row-1"><b>Row 1 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row1" value={this.props.defaultProps.row1} onChange={getValue} /></div>
							<div className="row-input row-2" style={{display:'none'}}><b>Row 2 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row2" value={this.props.defaultProps.row2} onChange={getValue} maxLength={50} /></div>
							<div className="row-input row-3" style={{display:'none'}}><b>Row 3 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row3" value={this.props.defaultProps.row3} onChange={getValue} maxLength={50} /></div>
							<div className="row-input row-4" style={{display:'none'}}><b>Row 4 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row4" value={this.props.defaultProps.row4} onChange={getValue} maxLength={50} /></div>
							<div className="row-input row-5" style={{display:'none'}}><b>Row 5 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row5" value={this.props.defaultProps.row5} onChange={getValue} maxLength={50} /></div>
						</div>
						:null}
						
						{this.props.defaultProps.changeLikert ?
						<div className="col-md-12 form-group" id='likert'>
							<label>Score text</label>
							<div className="grid-sacale">
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.lowestText} name="lowestText" onChange={getValue} maxLength={50} />
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.highestText} name="highestText" onChange={getValue} maxLength={50} />
							</div>
							
							<div className="likert-row">
							<label> Number of Rows </label>
							 
							<select className="form-control form-group number-rows small-width" value={this.props.defaultProps.numberRows} name="numberRows" onChange={getValue}>
								<option value='1'>1</option>
								<option value='2'>2</option>
								<option value='3'>3</option>
								<option value='4'>4</option>
								<option value='5'>5</option>
							</select>
							</div>
							
							<div className="row-input row-1"><b>Row 1 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row1" onChange={this.getValue} value={this.props.defaultProps.row1} maxLength={20}/></div>
							<div className="row-input row-2" style={{display:'none'}}><b>Row 2 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row2" onChange={getValue} value={this.props.defaultProps.row2} maxLength={50} /></div>
							<div className="row-input row-3" style={{display:'none'}}><b>Row 3 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row3" onChange={getValue} value={this.props.defaultProps.row3} maxLength={50} /></div>
							<div className="row-input row-4" style={{display:'none'}}><b>Row 4 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row4" onChange={getValue} value={this.props.defaultProps.row4} maxLength={50} /></div>
							<div className="row-input row-5" style={{display:'none'}}><b>Row 5 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row5" onChange={getValue} value={this.props.defaultProps.row5} maxLength={50} /></div>
						</div>
						:null}
						
						{this.props.defaultProps.changeWeighted ?
						<div className="col-md-12 form-group" id='weighted' >
							<label>How many options?</label>
							<select className="form-control number-rows small-width form-group" value={this.props.defaultProps.numberRows} name="numberRows" onChange={getValue}>
								<option value='2'>2</option>
								<option value='3'>3</option>
								<option value='4'>4</option>
								<option value='5'>5</option>
								<option value='6'>6</option>
								<option value='7'>7</option>
								<option value='8'>8</option>
								<option value='9'>9</option>
								<option value='10'>10</option>
							</select>
							
							{optionNumber.map((val, i) => (
								<div key={i} className={"row-input row-"+val} style={{display: val > 2 ? 'none' : ''}} ><b>{val} </b><DebounceInput debounceTimeout={2000} type="text" className="form-control weight-txt" name={"row"+val} onChange={getValue} value={this.props.defaultProps['row'+val]} /> <DebounceInput debounceTimeout={2000} type="number" className="form-control weight-val" name={"weight"+val} onChange={getValue} min="0" max="100" value={this.props.defaultProps['weight'+val]} /></div>
							))}
							
							<label> Number of Grid Columns </label>
							 
							<select className="form-control number-rows small-width" name="numberOfGrid" value={this.props.defaultProps.numberOfGrid} onChange={getValue}>
								<option value='1'>1</option>
								<option value='2'>2</option>
								<option value='3'>3</option>
								<option value='4'>4</option>
							</select>
							
							<label> Arrangement of options </label>
							<div><input className="order-input" type="radio" name="arraignment" checked={this.props.defaultProps.arraignment == 'provided' ? 'checked' : ''}  value="provided" onChange={getValue} />In order provided</div>
							<div><input className="order-input" type="radio" name="arraignment" checked={this.props.defaultProps.arraignment == 'alphabetical' ? 'checked' : ''} value="alphabetical" onChange={getValue} />Alphabetical</div>
							<div><input className="order-input" type="radio" name="arraignment" checked={this.props.defaultProps.arraignment == 'random' ? 'checked' : ''} value="random" onChange={getValue} />Random</div>
							<br />
						</div>
						:null}
						
						{this.props.defaultProps.changeWcalculation ?
						<div className="col-md-12 form-group" id='wcalculation'>
							<label> Calculation type: </label>
							<select className="form-control" value={this.props.defaultProps.calculationType} name="calculationType" onChange={getValue}>
								<option value="">Please Select</option>
								<option value="wsum">Sum Weight Values</option>
								<option value="wavg">Average Weight Values</option>
								<option value="wcount">Count Weight Values</option>
								<option value="wrcount">Count Ratio Weight Values</option>
							</select>
							<br />
							<label> Select the Field Name to include in the calculation:</label>
							{ this.props.wcalculationFields() }
						</div>
						:null}
						
						{/* this.props.defaultProps.changeAnsOpt || this.props.defaultProps.changeRadioPlus ?
						<div className="col-md-12 form-group">
							<label>Answer Options (separated by comma):</label> 
							
							<div className="float-right">
								<input id={"preserve"} className="btn code-dialog css-checkbox" name="preserveOrder" type="checkbox" onChange={getValue} checked={this.props.defaultProps.preserveOrder ? 'checked' : ''} />
								<label htmlFor={"preserve"} className="css-label lite-blue-check">Preserve Order</label>
							</div>
							
							<textarea id="optionInput" className="form-control" name="changeAnsInpText" value={this.props.defaultProps.changeAnsInpText} onChange={getValue} type="text" ></textarea>
						</div>
						:null */}
						
						{ this.props.defaultProps.changeAnsOpt || this.props.defaultProps.changeRadioPlus ?
						<div className="col-md-12 form-group">
							<label>Answer Options:</label> 
							
							<div className="float-right">
								<input id={"preserve"} className="btn code-dialog css-checkbox" name="preserveOrder" type="checkbox" onChange={getValue} checked={this.props.defaultProps.preserveOrder ? 'checked' : ''} />
								<label htmlFor={"preserve"} className="css-label lite-blue-check">Preserve Order</label>
							</div>
							
							
							<div className="option-keyvalue">
								<div className="keyvalue">
									<label>Option</label> 
									<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="optionKey" />
								</div>
								<div className="keyvalue-v">
									<button type="button" className="btn btn-primary" onClick={this.props.addOption}>+ Option</button>
								</div>
							</div>
							
							<div className="option-value">
								{/* optionsKeyHtml */}
								
								{ optionkeys ?
									<SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
										{optionkeys.map((value, index) => (
										  <SortableItem2 key={index} index={index} value={value} /> 
										))}
									</SortableContainer>
								:null}
							</div>
							{/* <textarea id="optionInput" className="form-control" name="changeAnsInpText" value={this.props.defaultProps.changeAnsInpText} onChange={getValue} type="text" ></textarea> */}
						</div>
						:null}
						
						{this.props.defaultProps.changeGridCheck ?
						<div className="col-md-6 form-group">
							
							<label> Number of Rows </label>
							 
							<select className="form-control number-rows form-group small-width" value={this.props.defaultProps.numberRows} name="numberRows" onChange={this.getValue}>
								<option value='1'>1</option>
								<option value='2'>2</option>
								<option value='3'>3</option>
								<option value='4'>4</option>
								<option value='5'>5</option>
							</select>
							
							<div className="row-input row-1"><b>Row 1 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row1" value={this.props.defaultProps.row1} onChange={getValue} /></div>
							<div className="row-input row-2" style={{display:'none'}}><b>Row 2 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row2" value={this.props.defaultProps.row2} onChange={getValue} maxLength={50} /></div>
							<div className="row-input row-3" style={{display:'none'}}><b>Row 3 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row3" value={this.props.defaultProps.row3} onChange={getValue} maxLength={50} /></div>
							<div className="row-input row-4" style={{display:'none'}}><b>Row 4 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row4" value={this.props.defaultProps.row4} onChange={getValue} maxLength={50} /></div>
							<div className="row-input row-5" style={{display:'none'}}><b>Row 5 text : </b><DebounceInput debounceTimeout={2000} type="text" className="form-control" name="row5" value={this.props.defaultProps.row5} onChange={getValue} maxLength={50} /></div>
						</div>
						:null}
						
						<div className="col-md-12 form-group">
							<label> Response Data Format </label>
							<HtmlTooltip
								title={
								  <React.Fragment>
									<p className="mb-1"><b color="inherit">Boolean: Indicates a true or false value.</b>
									<br />
									{'Example: true or false'}
									</p>
									<p className="mb-1"><b color="inherit">String: A sequence of characters, often used to represent text.</b>
									<br />
									{'Example: "Hello, world!"'}
									</p>
									
									<p className="mb-1"><b color="inherit">Numeric: Represents a number, which can be an integer or a decimal.</b>
									<br />
									{'Example: 42 or 3.14159'}
									</p>
									<p className="mb-1"><b color="inherit">Date: Represents a specific day in the format YYYY-MM-DD.</b>
									<br />
									{'Example: 2024-04-22'}
									</p>
									
									<p className="mb-1"><b color="inherit">Timestamp: Represents a specific point in time, often down to milliseconds.</b>
									<br />
									{'Example: 2024-04-22T14:30:00Z'}
									</p>
									<p className="mb-1"><b color="inherit">UUID (Universally Unique Identifier): A 128-bit identifier used to uniquely identify information in computer systems.</b>
									<br />
									{'Example: 123e4567-e89b-12d3-a456-426614174000'}
									</p>
									
									<p className="mb-1"><b color="inherit">Array: An ordered list of elements, which can be of any data type.</b>
									<br />
									{'Example: [1, "two", 3.0, true]'}
									</p>
									<p className="mb-1"><b color="inherit">JSON (JavaScript Object Notation): A lightweight data-interchange format, easy for humans to read and write, and easy for machines to parse and generate.</b>
									<br />
									{'Example: {"name": "John", "age": 30}'}
									</p>
									</React.Fragment>
								}
							  >
							<button type="button" className="btn"><i className="fas fa-info-circle"></i></button>
						    </HtmlTooltip>
							
							<select className="form-control" value={defaultProps.responseDataFormat} name="responseDataFormat" onChange={getValue} >
								<option value="">Select Response Data Format</option>
								<option value="Boolean">Boolean</option>
								<option value="String">String</option>
								<option value="Numeric">Numeric</option>
								<option value="Date">Date</option>
								<option value="Timestamp">Timestamp</option>
								<option value="UUID">UUID</option>
								<option value="Array">Array</option>
								<option value="JSON">JSON</option>
							</select>
						</div>
						
						{this.props.defaultProps.changeAnsOptKey ?
						<div className="col-md-12 form-group">
							<label>Answer Options:</label> 
							
							<div className="float-right">
								<input id={"preserve"} className="btn code-dialog css-checkbox" name="preserveOrder" type="checkbox" onChange={getValue} checked={this.props.defaultProps.preserveOrder ? 'checked' : ''} />
								<label htmlFor={"preserve"} className="css-label lite-blue-check">Preserve Order</label>
							</div>
							
							
							<div className="option-keyvalue">
								<div className="keyvalue">
									<label>Option</label> 
									<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="optionKey" />
								</div>
								<div className="keyvalue">
									<label>Key Value</label>
									<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="optionKeyValue" />
								</div>
								<div className="keyvalue-v">
									<button type="button" className="btn btn-primary" onClick={this.props.addOptionKey}>+ Option</button>
								</div>
							</div>
							
							<div className="option-value">
								{/* optionsKeyHtml */}
								
								{ optionkeys ?
									<SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
										{optionkeys.map((value, index) => (
										  <SortableItem key={index} index={index} value={value} /> 
										))}
									</SortableContainer>
								:null}
							</div>
							{/* <textarea id="optionInput" className="form-control" name="changeAnsInpText" value={this.props.defaultProps.changeAnsInpText} onChange={getValue} type="text" ></textarea> */}
						</div>
						:null}
						
						{this.props.defaultProps.changeAnsOptHidden ?
						<div className="col-md-12 form-group">
							<div className="key-option">
								<label> Select List of Keyed Options to Define: </label>
								<select className="form-control build-select" value={defaultProps.SelectedKeyed} name="SelectedKeyed" onChange={this.getValue}>
									<option value="">Please Select</option>
									{defaultProps.KeyOptionQ14.map((val, i) => (
										<option key={i} value={val[117]}>{val[4]}</option>
									))}
								</select>
							</div>
							<div className="option-key-name">
								<label> Select Keynames for Options Specific Values: </label>
								<select className="form-control option-key-name-bx" name="SelectedKeyNames" value={defaultProps.SelectedKeyNamesArray} onChange={getValue} multiple>
									{optionkeys.map((val, i) => {
										let optionKey = val.split('|');
										return(<option key={i} value={optionKey[1]}>{optionKey[1]}</option>);
										
									})}
								</select>
							</div>
							<div className="form-group">
								<label>Add New KeyName</label> 
								<div className="input-group mb-3">
									<DebounceInput debounceTimeout={2000} type="text" className="form-control" placeholder="Keyname" name="optionKeyName"/>
									<div className="input-group-append">
										<button className="btn btn-primary" type="button" onClick={this.props.addOptionKeyName}>Button</button>
									</div>
								</div>
							</div>
							
							{defaultProps.SelectedKeyed ?
								<div className="form-group">
								<label>Manage Hidden Dimensions for Each Keyed Option</label> 
								
								{defaultProps.KeyOptionQ14.map((val, i) =>{
								
									if(val[117] == defaultProps.SelectedKeyed){
										let optkeys = val[6].split(',');
										optkeys = optkeys.filter(function(x) {
											 return x !== '';
										});
										let namesArrayLan = defaultProps.SelectedKeyNamesArray.length;
										return (
											<div className="input-group mb-3">
												<h6>{val[4]}</h6>
												<table className="table dimension-table">
												{optkeys.map((vl, i) =>{
													let txt = vl.split('|');
													
													let strNew = defaultProps.SelectedKeyed+txt[1];
													strNew = strNew.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '');
													
													let valLen = defaultProps[strNew];
													let img = 'opt-3.png';
													if(namesArrayLan == valLen){
														img = 'opt-1.png';
													}else if(valLen < namesArrayLan && valLen != 0){
														img = 'opt-2.png';
													}
										
													return (
														<tr>
														<td width="60%">{txt[1]}</td>
														<td width="20%">{txt[2]}</td>
														<td><button type="button" className="btn btn-padding" onClick={()=>this.props.showDimantionPop(val,txt)}><img src={href+'/view-details-2.png'} alt="View Details" width="20" height="20" /></button></td>
														<td><img src={href+'/'+img} alt="View Details" width="20" height="20" /></td>
														</tr>
													);
												})}
												</table>	
											</div>
										);
									}
								})}
							</div>
							:null}
							
						</div>
						:null}
						
						{this.props.defaultProps.changeOneRadioPlus ?
						<div className="col-md-12 form-group">
							<label>Answer Option:</label>
							<DebounceInput debounceTimeout={2000} id="optionInput" className="form-control" name="changeAnsInpText" value={this.props.defaultProps.changeAnsInpText} onChange={getValue} type="text" />
						</div>
						:null}
						
						{this.props.defaultProps.checkboxPlusLable ?
						<div className="col-md-12 form-group">
							<label> Visible Text Label </label>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="visibleTxtLabel" value={this.props.defaultProps.visibleTxtLabel} onChange={getValue} />
						</div>
						:null}
						
						{this.props.defaultProps.checkboxPlusLable ?
						<div className="col-md-12 form-group">
							<label> Output Text Label: </label>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="outputTxtLabel" value={this.props.defaultProps.outputTxtLabel} onChange={getValue} />
						</div>
						:null}
						
						{this.props.defaultProps.changeRadioPlus || this.props.defaultProps.changeOneRadioPlus ?
						<div className="col-md-12 form-group">
							<label> Left radio button text: </label>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="leftButtonTxt" value={this.props.defaultProps.leftButtonTxt} onChange={getValue} />
						</div>
						:null}
						
						{this.props.defaultProps.changeRadioPlus || this.props.defaultProps.changeOneRadioPlus ?
						<div className="col-md-12 form-group">
							<label> Right radio button text - </label>
							<span className="txt-additional"> Selecting this option will open additional text box</span>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="rightButtonTxt" value={this.props.defaultProps.rightButtonTxt} onChange={getValue} />
						</div>
						:null}
						
						{this.props.defaultProps.changeRadioPlus || this.props.defaultProps.changeOneRadioPlus ?
						<div className="col-md-12 form-group">
							<label> Text box Label: </label>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="buttonLabel" value={this.props.defaultProps.buttonLabel} onChange={getValue} />
						</div>
						:null}
						
						{this.props.defaultProps.showStyleQ4 ?
						<div className="col-md-12 form-group">
							<label> Style: </label>
							<select className="form-control" value={this.props.defaultProps.styleQ4} name="styleQ4" onChange={getValue}>
								<option value="checkbox">Checkbox</option>
								<option value="standard">Standard</option>
							</select>
						</div>
						:null}
						
						{this.props.defaultProps.changeOutOpt && this.props.defaultProps.chooseAnsType != 'hidden' ?
						<div className="col-md-12 form-group">
                            <label>Output Text: </label>
                            <DebounceInput element="textarea" debounceTimeout={4000} className="form-control" rows="2" value={this.props.defaultProps.changeOutInpText} onChange={getValue} name="changeOutInpText" />
						</div>
						:null}
						
						{this.props.defaultProps.apiVideo ?
						<div className="col-md-12 form-group">
                            <label> Analysis Video Link: </label>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="analysisVideo" value={this.props.defaultProps.analysisVideo} onChange={getValue} />
						</div>
						:null}
						
						{this.props.defaultProps.apiVideo ?
						<div className="col-md-12 form-group">
                            <label>Report PDF Link: </label>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="reportPdf" value={this.props.defaultProps.reportPdf} onChange={getValue} />
						</div>
						:null}
						
						{this.props.defaultProps.changebuttonLabel ?
						<div className="col-md-12 form-group">
							<label> Button Label: </label>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="buttonLabel" value={this.props.defaultProps.buttonLabel} onChange={getValue} />
						</div>
						:null}
						
						{this.props.defaultProps.changeSmart ?
						<div className="col-md-8" id='noneAbove'>
						   <input name="noneAbove" type="checkbox" checked={this.props.defaultProps.noneAbove != '' ? true : false} value="None of the Above" onChange={this.getValue} /> Include "None of the Above"
						</div>
						:null}
						
						{this.props.defaultProps.changeSliderText ?
						<div>
						<div className="col-md-12 form-group">
							<label>Enter text for minimum (only allow max 20 characters):</label>
							<textarea className="form-control" value={this.props.defaultProps.sliderMinText} name="sliderMinText" onChange={getValue} type="text" maxLength={50} ></textarea>
						</div>
						<div className="col-md-12 form-group">
							<label>Enter text for maximum(only allow max 20 characters):</label>
							<textarea className="form-control" value={this.props.defaultProps.sliderMaxText} name="sliderMaxText"  onChange={getValue} type="text" maxLength={50} ></textarea>
						</div>
						
						<div className="col-md-12 form-group">
							<label>Add a Minimum Value:</label>
							<DebounceInput debounceTimeout={2000} className="form-control" name="sliderMinValue" type="number" value={this.props.defaultProps.sliderMinValue} onChange={getValue} />
						</div>
						
						<div className="col-md-12 form-group">
							<label>Add a Maximum Value:</label>
							<DebounceInput debounceTimeout={2000} className="form-control" name="sliderMaxValue" type="number" value={this.props.defaultProps.sliderMaxValue} onChange={getValue} />
						</div>
						
						<div className="col-md-12 form-group">
							<label>Add a Step Value:</label>
							<DebounceInput debounceTimeout={2000} className="form-control" name="sliderStepValue" type="number" value={this.props.defaultProps.sliderStepValue} onChange={this.getValue}/>
						</div>
						
						</div>
						:null}
						
						{this.props.defaultProps.showInlineVideo ?
						<div className="col-md-12 form-group" id='inlineVideoDiv'>
							<label>Upload Inline Video:</label>
							<div>
							<button type="button" className="btn btn-primary btn-insert-video" onClick={this.props.insertInlinVideo}>Insert Video</button>&nbsp;&nbsp;
							<button type="button" className="btn btn-upload-video" onClick={this.props.uploadFylesVideo}><i className="fa fa-upload" aria-hidden="true"></i></button>
							</div>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" name="inlineVideoLink" value={this.props.defaultProps.inlineVideoLink} onChange={getValue} placeholder="http://"/>
							
							{/* <BasicTextFields uploadVideoPath={this.props.uploadVideoPath} /> */}
							<div id={"inlineVideo-"+fieldId}></div>
						</div>
						:null}
						
						{this.props.defaultProps.showImageUpload ?
						<div className="col-md-8 form-group" id='imageDiv'>
							<button type="button" className="btn btn-primary btn-insert-video" onClick={this.props.insertFylesImage}>Insert Image from My Fyles site</button>
							<label>Upload An Image:</label>
							{/*<button data-target="#directory" data-toggle="modal" onClick={this.props.getDirectoryImages}>open directory...</button>*/}
							<input id="fileinput" type="file" accept="image/gif, image/jpeg, image/png" onChange={this.props.getDirectoryImages} />
							{this.props.imagePath !== '' ? <img src={this.props.imagePath} width="280px" height="auto"/>:null}
						</div>
						:null}
						
						<div id="addNote" className="col-md-12 form-group">
							<label>Additional Notes:</label> 
							<label>
							<button type="button" className="btn btn-primary btn-insert-video" onClick={this.props.insertVideo}>Insert Video</button>&nbsp;&nbsp;
							<button type="button" className="btn btn-primary btn-insert-video" onClick={this.props.insertFylesImageNote}>Insert Image</button>&nbsp;&nbsp;
							<button type="button" className="btn btn-upload-video" onClick={this.props.uploadFylesVideo}><i className="fa fa-upload" aria-hidden="true"></i></button>
							</label>
							<Editor
								editorState={this.props.defaultProps.editorState}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								wrapperStyle={{ border: "1px solid black", marginBottom: "20px" }}
								editorStyle={{ height: "220px", padding: "10px",maxWidth:'100%',maxHeight:'100%'}}
								editorClassName="editorClassName"
								onEditorStateChange={this.props.onEditorStateChange}
								toolbar={{
								inline: { inDropdown: true },
								list: { inDropdown: true },
								textAlign: { inDropdown: true },
								link: { inDropdown: true },
								history: { inDropdown: true },
								image: { uploadCallback: this.props._uploadImageCallBack, alt: { present: true, mandatory: false },previewImage: true },
								inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel'
								}}
							/>
						</div>
						
						{role == 'Administrator' ?
						<div id="addTag" className="col-md-12 form-group">
							<label>Static FieldID</label>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.staticFieldID} name="staticFieldID" onChange={this.getValue}/>
						</div>
						: null}
						
						{role != 'Builder' && role != 'MdsBuilder' ?
						<div id="addTag" className="col-md-12 form-group">
							<label>Static FieldName Identifier</label>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.fieldIdentifier} name="fieldIdentifier" onChange={this.getValue}/>
						</div>
						: null}
						
						<div id="addTag" className="col-md-12 form-group">
							<input type="checkbox" value='Y' checked={this.props.defaultProps.altTextBox} name="altTextBox" onChange={this.props.setAsGroup}/> Alt Text
						</div>
						
						<div className="col-md-12 form-group" style={{display: this.props.defaultProps.altTextBox ? 'block' : 'none'}}>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.fieldAltText} name="fieldAltText" onChange={this.getValue} placeholder="alt text"/>
						</div>
						
						{role != 'Builder' && role != 'MdsBuilder' ?
						<div id="addTag" className="col-md-12 form-group">
							<label>{"<<Tags>>"}</label>
							<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={this.props.defaultProps.addTagsText} name="addTagsText" onChange={this.getValue}/>
						</div>
						: null}
						{this.props.defaultProps.chooseAnsType.toLowerCase() == 'q6' || this.props.defaultProps.chooseAnsType.toLowerCase() == 'q8' || this.props.defaultProps.chooseAnsType.toLowerCase() == 'timer' ? 
						<div className="col-md-12 form-group">
							<label>Choose Validation Type:</label>
							{this.props.defaultProps.chooseAnsType.toLowerCase() == 'q6' ? 
								<select className="form-control form-group validation-box" value={this.props.defaultProps.validationType} name="validationType" onChange={getValue}>
									<option value="V1">V1 Text</option>
									<option value="V2">V2 Text Alphabet Only (no numbers)</option>
									<option value="V3">V3 Whole +Numbers and Zero</option>
									<option value="V4">V4 Whole +Numbers no Zero</option>
									<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
									<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
									<option value="V7">V7 Percentage Positive Only</option>
									<option value="V8">V8 Fractions Positive Only</option>
									<option value="V14">V14 Whole -Numbers and no Zero</option>
								</select>
							: this.props.defaultProps.chooseAnsType.toLowerCase() == 'q8' ?
							
								<select className="form-control form-group validation-box" value={this.props.defaultProps.validationType} name="validationType" onChange={getValue}>
									<option value="">Please Select</option>
									<option value="V9">V9 Dates Past</option>
									<option value="V10">V10	Dates Today Only</option>
									<option value="V11">V11	Dates Future</option>
								</select>
								
							: this.props.defaultProps.chooseAnsType.toLowerCase() == 'timer' ?
							
								<select className="form-control form-group validation-box" value={this.props.defaultProps.validationType} name="validationType" onChange={getValue}>
									<option value="">Please Select</option>
									<option value="V12">V12	Time hh:mm:ss:ssss</option>
									<option value="V13">V13 Time ss:ssss</option>
								</select>
							
							: null}
							
							{this.props.defaultProps.showMinMax ?
							<div>
							<div className="validation form-group">
								<label>{"Min Value:"}</label>
								<DebounceInput debounceTimeout={2000} type="text" className="form-control min-val" name="minValue" value={this.props.defaultProps.minValue} onChange={getValue}/>
							</div>
							<div className="validation form-group">
								<label>{"Max Value:"}</label>
								<DebounceInput debounceTimeout={2000} type="text" className="form-control max-val" name="maxValue" value={this.props.defaultProps.maxValue} onChange={getValue}/>
							</div>
							
							</div>
							:null}
						</div>
						: null}
						{/* this.props.defaultProps.chooseAnsType.toLowerCase() == 'q6' || this.props.defaultProps.chooseAnsType.toLowerCase() == 'q8' || this.props.defaultProps.chooseAnsType.toLowerCase() == 'timer' ? 
						<div className="col-md-12 form-group">
							<label>Response Data Format:</label>
								<select className="form-control form-group validation-box" value={this.props.defaultProps.responseDataFormat} name="responseDataFormat" onChange={getValue}>
									<option value="Boolean">Boolean</option>
									<option value="String">String</option>
									<option value="Numeric">Numeric</option>
									<option value="Date">Date</option>
									<option value="Timestamp">Timestamp</option>
									<option value="UUID">UUID</option>
									<option value="Array">Array</option>
									<option value="JSON">JSON</option>
								</select>
						</div>
						: null */}
						{this.props.defaultProps.hasParentHeader ?
						<div className="col-md-8 form-group">
							<input type="checkbox" value='Y' checked={this.props.defaultProps.asGroup} className="asGroup" name="asGroup" onChange={this.props.setAsGroup}/> Available as a Group
						</div>
						:null}
						
						{/*<div className="col-md-8 form-group">
							<label> Public Field Bank: </label>
							<select className="form-control" value={this.props.defaultProps.PFB} name="PFB" onChange={getValue}>
								<option value="Y">Yes</option>
								<option value="N">No</option>
							</select>
						</div>*/}
						{/*this.props.defaultProps.showCompany ?
						<div className="col-md-8 form-group">
							<label> Company Name: </label>
							<select className="form-control company-select" value={this.props.defaultProps.chooseCompany} name="chooseCompany" onChange={getValue}>
								<option value="">Please Select</option>
								{companies.map((company, i) => (
									<option key={i} value={company}>{company}</option>
								))}
							</select>
						</div>
						:null
						<div className="col-md-8 form-group">
							<label> Include Tracking Code </label>
							<select className="form-control company-select" value={defaultProps.chooseTrackingArray} name="chooseTracking" onChange={getValue} multiple>
								{trackingCode.map((code, i) => (
									<option key={i} value={code}>{code}</option>
								))}
							</select>
						</div>
						*/}
						<div className="col-md-8 form-group">
							<label> Internal Data Persistence Level </label>
							<select className="form-control company-select" value={defaultProps.dataPersistence} name="dataPersistence" onChange={getValue} >
								<option value="">Please Select</option>
								<option value="Facility">Facility</option>
								<option value="Branch">Branch</option>
								<option value="User">User</option>
								<option value="Patient">Patient</option>
								<option value="Prescription">Prescription</option>
								<option value="Visit">Visit</option>
								<option value="Day">Day</option>
								{/* <option value="Today">Today</option>
								<option value="Encounter">Encounter</option>
								<option value="Job">Job</option>
								<option value="All">All</option> */}
							</select>
							
						</div>
						{defaultProps.dataPersistence ? 
							<div className="col-md-8 form-group">
								<label>Internal Data key</label>
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={defaultProps.persistencekey} name="persistencekey" onChange={getValue}/>
							</div>
						:null}
						{defaultProps.dataPersistence ? 
							<div className="col-md-8 form-group">
								<label>External Data key</label>
								<DebounceInput debounceTimeout={2000} type="text" className="form-control" value={defaultProps.exPersistencekey} name="exPersistencekey" onChange={getValue}/>
							</div>
						:null}
						{dataMode == 'Child' && this.props.defaultProps.showAddMds ? 
							<div className="col-md-8 form-group">
								<label> Add to MDS </label>
								<select className="form-control company-select add-select" name="addParentMds" onChange={this.getValue}>
									<option value="">Please Select</option>
									{mdsSheets.map((val, i) => (
										<option key={i} value={val}>{val}</option>
									))}
								</select>
							</div>
						:null}
						{dataMode == 'Child' && this.props.defaultProps.showAddMds ?
							<div className="col-md-8 form-group">
								<label> Add to Group </label>
								<select className="form-control company-select add-select" name="addGroupMds" onChange={getValue}>
									<option value="">Please Select</option>
									{parentDataSets.map((comment,i) =>{
										if(i > 0 && (comment[126] == 'Y' || comment[127] == 'Y')){
											let type = comment[5]
											let plh = comment[4]+'('+type+')';
											return(
												<option key={i} value={comment[10]}>{plh}</option>
											);
										}
									})}
								</select>
							</div>
						:null}
						
						<div className="col-md-8 form-group">
						   <input name="dataElement" type="checkbox" checked={this.props.defaultProps.dataElement} onChange={this.getValue} /> Protected Data Element
						</div>
						
						{ this.props.defaultProps.dataElement ? 
							<div className="col-md-8 form-group">
								<select className="form-control company-select" value={defaultProps.dataElementOption} name="dataElementOption" onChange={getValue} >
									<option value="Public">Public</option>
									<option value="DataClassification">DataClassification</option>
									<option value="Confidential">Confidential</option>
									<option value="Restricted">Restricted</option>
								</select>
							</div>
						:null}
						
						<div className="col-md-8 form-group">
						   <input name="legacyData" type="checkbox" checked={this.props.defaultProps.legacyData} value="Y" onChange={this.getValue} /> Maps to legacy OPIE data
						</div>
							
						{role != 'Builder' && role != 'MdsBuilder' ?
						<div className="col-md-8 form-group">
						   <input name="dataAgg" type="checkbox" checked={this.props.defaultProps.dataAgg} value="Y" onChange={this.getValue} /> Include in data Agg
						</div>
						:null}
						{role != 'Builder' && role != 'MdsBuilder' ?
						<div className="col-md-8 form-group">
						   <input name="autoPopulate" type="checkbox" checked={this.props.defaultProps.autoPopulate} value="Y" onChange={this.getValue} /> Auto Populate
						</div>
						:null}
						{role != 'Builder' && role != 'MdsBuilder' ?	
						<div className="col-md-8 form-group auto-populate-tags" style={{display: this.props.defaultProps.autoPopulate ? 'block' : 'none'}}>
							<label> Auto Tags</label>
							<select className="form-control company-select add-select" value={this.props.defaultProps.autoPopulateTags} name="autoPopulateTags" onChange={getValue}>
								<option value="">Please Select</option>
								{ogTags.map((val,i) =>{
									if(i > 0){
										return(
											<option key={i} value={val}>{val}</option>
										);
									}
								})}
							</select>
						</div>
						:null}
						{role != 'Builder' && role != 'MdsBuilder' ?
						<div className="col-md-8 form-group">
						   <input name="fieldDeactivated" type="checkbox" checked={this.props.defaultProps.fieldDeactivated == 'Y' ? true : false} value="Y" onChange={this.getValue} /> Deactivated
						</div>
						:null}
						
						{role == 'Administrator' ?
						<div className="col-md-8 form-group">
						   <input name="fieldRegistry" type="checkbox" checked={this.props.defaultProps.fieldRegistry == 'Y' ? true : false} value="Y" onChange={this.getValue} /> Registry Data
						</div>
						:null}
						
						<div className="col-md-8 form-group">
							<input id="inNotef" name="fieldInternalNoteBox" type="checkbox" checked={defaultProps.fieldInternalNoteBox} onChange={getValue}/> Internal Notes
						</div>
						
						{this.props.defaultProps.fieldInternalNoteBox ?
						<div className="col-md-12 form-group">
							<DebounceInput element="textarea" debounceTimeout={4000} className="form-control" rows="4" value={this.props.defaultProps.fieldInternalNote} onChange={getValue} name="fieldInternalNote" />
						</div>
						:null}
						
						<div className="col-md-12 form-group">
							<label> Builder Notes about this field: </label>
							<DebounceInput element="textarea" debounceTimeout={4000} className="form-control" rows="4" value={this.props.defaultProps.builderNote} onChange={getValue} name="builderNote" />
						</div>
						
						<div className="col-md-12">
							<button type="button" className="btn btn-info btn-update-edit" onClick={this.props.updateField}>Update</button>
						</div>
					</div>
				</div>
			:null}
			{showTrackerDetails ?
				<div className="tracker-edit-section">
					<div className="show-status-view">
						<label className="showAllSwitch">
							<input id="showFormContent" type="checkbox" name="showViewForm" onClick={()=>showTrackerViewFun()} />
							<span className="slider round showAllSlide"></span>
						</label> View Form Content <span className="fab-jobid">Job# {defaultProps.fabJobNumber}</span>
					</div>
					
					<div className="row-input">
						<label className="label-control">Patient ID</label>
						<DebounceInput debounceTimeout={2000} className="form-control pat0id" type="text" name='fabPatientId' value={defaultProps.fabPatientId} disabled />
					</div>
					<div className="row-input">
						<label className="label-control">Patient firstname</label>
						<DebounceInput debounceTimeout={2000} className="form-control pat0id" type="text" name='patientFirstName' value={defaultProps.patientFirstName} disabled />
					</div>
					<div className="row-input">
						<label className="label-control">Patient lastname</label>
						<DebounceInput debounceTimeout={2000} className="form-control pat0id" type="text" name='patientLastName' value={defaultProps.patientLastName} disabled />
					</div>
					
					<div className="row-input">
						<label className="label-control">Sending practitioner</label>
						<select className="form-control" name="fabPractitioner" onChange={getValue} value={defaultProps.fabPractitioner} disabled >
							<option value={defaultProps.fabPractitioner}>{defaultProps.fabPractitioner}</option>
						</select>
					</div>
					
					<div className="row-input">
						<label className="label-control"> Select role of responsible person </label>
						<select className="form-control" name="personRole" onChange={getValue} value={defaultProps.personRole}>
							<option value="">Please Select</option>
							{this.props.responsibleRole.map((val, i) => (
								<option key={i} value={val}>{val}</option>
							))}
						</select>
					</div>
					<div className="row-input">
						<label className="label-control"> Select name of responsible person </label>
						<select className="form-control" name="personName" onChange={getValue} value={defaultProps.personName}>
							<option value="">Please Select</option>
							{this.props.responsiblePerson.map((val, i) => (
								<option key={i} value={val}>{val}</option>
							))}
						</select>
					</div> 	
					<div className="row-input">
						<label className="label-control"> Select current status </label>
						<select className="form-control" name="currentStatus" onChange={getValue} value={defaultProps.currentStatus}>
							{this.props.statusByfacility.map((val, i) => (
								<option key={i} value={val}>{val}</option>
							))}
						</select>
					</div>
					<div className="row-input-date">
						<label className="label-control"> Select due date </label>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<Stack spacing={3}>
								<DesktopDatePicker
									label=""
									inputFormat="MM/dd/yyyy"
									value={this.state.startDate ? this.state.startDate : defaultProps.dueDate}
									onChange={this.handleChange}
									renderInput={(params) => <TextField {...params} />}
								/>
							</Stack>
						</LocalizationProvider>
					</div>
					<div className="row-input">
						<label className="label-control">At risk of missing due date</label>
						<select className="form-control" name="statusDueDate" onChange={getValue} value={defaultProps.statusDueDate}>
								<option value={'No'}>No</option>
								<option value={'Yes'}>Yes</option>
								
						</select>
					</div>
					<div className="row-input">
						<label className="label-control"> Priority </label>
						<select className="form-control" name="formPriority" onChange={getValue} value={defaultProps.formPriority}>
								<option value={2}>Normal</option>
								<option value={1}>Low</option>
								<option value={3}>High</option>
						</select>
					</div>
					<div className="row-input">
						<label className="label-control"> Rank </label>
						<select className="form-control" name="formRank" onChange={getValue} value={defaultProps.formRank}>
							{rankOptions()}
						</select>
					</div>
					<div className="row-input-date">
						<label>Additional Notes:</label>
						<Editor
							editorState={defaultProps.editorState}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							wrapperStyle={{ border: "1px solid black", marginBottom: "20px" }}
							editorStyle={{ height: "220px", padding: "10px",maxWidth:'100%',maxHeight:'100%'}}
							editorClassName="editorClassName"
							onEditorStateChange={this.props.onEditorStateChange}
							toolbar={{
							inline: { inDropdown: true },
							list: { inDropdown: true },
							textAlign: { inDropdown: true },
							link: { inDropdown: true },
							history: { inDropdown: true },
							image: { uploadCallback: this.props._uploadImageCallBack, alt: { present: true, mandatory: false },previewImage: true },
							inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel'
							}}
						/>
					</div>
					{statusLogs.length > 0 ?
					<div className="row-input">
						<label className="label-control"> <b>Change Log </b></label>
						<div className="status-logs"><ul>{logsHtml}</ul></div>
					</div>
					:null}
					<div className="btn-checkbox">
						<div className="row">
							<div className="col-md-6">
								<input name="fabCompleted" type="checkbox" value="Y" checked={defaultProps.fabCompleted == 'Y' ? true : false} onChange={this.getValue} /> Fab Complete
							</div>
							<div className="col-md-6">
								<input name="markCompleted" type="checkbox" value="Y" checked={defaultProps.markCompleted == 'Y' ? true : false} onChange={this.getValue} /> Mark as Completed and hide from view
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<input name="allParts" type="checkbox" value="Y" checked={defaultProps.allParts == 'Y' ? true : false} onChange={this.getValue} /> All Parts are ready
							<br />
							<input name="redoInfo" value="Y" checked={defaultProps.redoInfo == 'Y' ? true : false} type="checkbox" onChange={this.getRedo} /> This is a Redo  
							{defaultProps.redoInfo == 'Y' ?
							<button type="button" className="btn" onClick={this.showErrorInfo}><i className="fa fa-flag"></i></button>
							:null}
						</div>
						<div className="col-md-6">
							<button type="button" className="btn btn-info btn-update-status" onClick={this.props.updateTrackerStatus}>Update Status</button>
						</div>
					</div>
				</div>
			:null}
		</div>            
	)}
}

export default BuildDetails;
