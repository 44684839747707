import React, { Component } from 'react'
import 'bootstrap';
import $ from 'jquery';
//import { Form,Button } from 'react-bootstrap';
//import ReactHtmlParser from 'react-html-parser';
import { generatehtmlfromlocation } from "../../Helpers/Common";
import Checkbox from '@mui/material/Checkbox';

//import FieldQ13 from "../Fields/Q13.js";
//import Launchpad from "../Fields/Launchpad";
//import FieldInlineImage from "../Fields/InlineImage.js";
//import FieldInlineVideo from "../Fields/InlineVideo.js";
//import FieldPdfApi from "../Fields/PdfApi.js";
import FieldAssessment from "../Fields/Assessment.js";
import FieldSubjective from "../Fields/Subjective.js";
//import FieldCalculation from "../Fields/Calculation.js";
import Checklist from "../Fields/Checklist";
import Radio from '@mui/material/Radio';
//import { noteHtml,noteModal,attentHTML} from "../Modal";
import { noteHtml,noteModal} from "../Modal";

export default class MainData extends Component {
    constructor(props){
        super(props)
        this.state = {
            checkedNeeds:new Map(),
            checkedApprove:new Map(),
            reviewRadio:'',
        }
		
    }
	
    classTypes(comment){
        let className = 'question';
		
		if(comment[15] == 'Y'){
			className = className+' field-required ';
		}else{
			className = className+' field-not-required ';
		}
		
		//let notInclude = '';
        if(comment[5].toLowerCase() == 'parent header'){
            className = 'parent-header';
        }else if(comment[5].toLowerCase() == 'child header 1'){
			className = 'header-1 ';
		}else if(comment[5].toLowerCase() == 'child header 2'){
			className = 'header-2 ';
		}else if(comment[5].toLowerCase() == 'child header 3'){
			className = 'header-3 ';
		}
		
		/* if(comment[5].toLowerCase() == 'boxbegin' || comment[5].toLowerCase() == 'boxend'){
			className = ' '+comment[5]+'-div ';
		} */
		
		if(comment[5] !== 'Parent Header' && comment[14] == 'N'){
            className = className+' not-include';
        }
		let needNote = this.props.needsNote.get(comment[0]);
		if(needNote){
			className = className+' attention';
		}
		
		if(!this.props.showResponseData && !this.props.delegateFilter && comment[14] == 'Y' && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
			$('#'+comment[0]).removeClass("not-include");
		}
		
		if(this.props.delegateFilter && comment[14] == 'Y'){
			let delegateFilter = this.props.delegateFilter;
			let uArray = this.props.delegateUsers.get(comment[0]);
			
			if(uArray.indexOf(delegateFilter) == -1 && (comment[5].toLowerCase() != 'parent header' && comment[5].toLowerCase() != 'child header 1' && comment[5].toLowerCase() != 'child header 2' && comment[5].toLowerCase() != 'child header 3')){
				className = className+' not-include';
			}else if(delegateFilter != '' && delegateFilter != 'CPO' && comment[37] == 'N'){
				className = className+' not-include';
			}
			
			setTimeout(function(){
				if(comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3'){
					$('#'+comment[0]).removeClass("not-include");
				}
			
				/* if(($('#'+comment[0]).find('.question').not(".not-include").length < 1 || $('#'+comment[0]).find('.header-1').not(".not-include").length < 1 || $('#'+comment[0]).find('.header-2').not(".not-include").length < 1 || $('#'+comment[0]).find('.header-3').not(".not-include").length < 1 ) && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
					$('#'+comment[0]).addClass("not-include");	
				} */
				if($('#'+comment[0]).find('.question').not(".not-include").length < 1  && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
					$('#'+comment[0]).addClass("not-include");	
				}
			}, 2000);
		}
		
		if(this.props.showResponseData){
			setTimeout(function(){
				if(($('#'+comment[0]).find('.question').not(".not-include").length < 1 || $('#'+comment[0]).find('.question').not(".not-response").length < 1) && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
					$('#'+comment[0]).addClass("not-include");	
				}
			}, 2000);
		}
		
		/* if($("input[name='customSwitchRequired']").prop("checked") == true){
			setTimeout(function(){
				if(($('#'+comment[0]).find('.question').not(".field-not-required").length < 1 || $('#'+comment[0]).find('.question').not(".not-response").length < 1) && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
					$('#'+comment[0]).addClass("not-include");	
				}
			}, 2000);
		} */
		if(comment[5].toLowerCase() == 'hidden'){
			className = 'hidden-field';
		}
		
		return className;
    }
	
	toggle(id){
		if(!$('#parent'+id.toLowerCase()).prop('disabled')){
			$('#parent'+id.toLowerCase()).find('i:first').toggleClass('fa fa-plus-square-o fa-1x mr-2  fa fa-minus-square-o fa-1x mr-2');
		}
		$('#parent'+id.toLowerCase()).prop('disabled', true);
		setTimeout(function(){
			$('#parent'+id.toLowerCase()).prop('disabled', false);
		}, 1000);
    }
	
	showStar(comment){
        let iTag
        this.props.sheetData.map((valu, i) => {
            if(comment[0] === valu[7] &&  comment[7]){
                iTag = <span><i className="fa fa-star" style={{fontSize:'20px',color:'yellow'}} data-toggle="modal" data-target={"#showStarModalYellow"+comment[0]}></i><i className="fa fa-star" style={{fontSize:'20px',color:'blue'}} data-toggle="modal" data-target={"#showStarModalBlue"+comment[0]}></i></span>
            }else if(comment[0] === valu[0] && comment[7] === valu[7] && comment[8]){
                iTag = <i className="fa fa-star" style={{fontSize:'20px',color:'blue'}} data-toggle="modal" data-target={"#showStarModalBlue"+comment[0]} ></i>
            }else if(comment[0] === valu[7]){
                iTag = <i className="fa fa-star" style={{fontSize:'20px',color:'yellow'}} data-toggle="modal" data-target={"#showStarModalYellow"+comment[0]} ></i>
            }
        })
        return iTag
    }
	
	getQueType(type,id,res){
		switch(type){
            case 'sig':
                return <i className="fas fa-edit" onClick={()=>this.showSign2(type,id)}></i>
            break;
            case 'q10':
                //return <Checkbox id={'type'+id} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} onClick={()=>this.showSign(type,id)} checked={res ? true : false}/>
                return <Checkbox id={'type'+id} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} onClick={()=>this.showSign(type,id)} />
            break;
            default:
                return null
        }
    }
	
	showSign=(type,id)=>{
		if($("#type"+id).prop("checked") == true){
			$('#'+type+id).css('display','block');
		}else if($("#type"+id).prop("checked") == false){
			$('.res_'+id).val('');
			$('#'+type+id).css('display','none');
		}
    }
	
	showSign2=(type,id)=>{
        $('#'+type+id).css('display','block')
    }
	
	subQuestions = (id,value) => {
		this.props.getResponseValue(id,value);
	}
	
	showNeedModal=(needIds,note)=>{
        this.setState({[needIds]:''});
		if(this.props.showResponseData){
			this.setState({[needIds]:note});
		}else{
			this.props.allData.map((valu, i) => {
				if(valu[0] === needIds){
					this.setState({[needIds]:valu[27]});
				}
			})
		}
		
        $('#needs'+needIds).modal('show');
    }
	
	handleNeedsNote=(e)=>{
		const item = e.target.name;
        this.setState({[item]:''});
		this.props.handleNeeds(e);
	}
	
	handleCopyFields=(e)=>{
		const item = e.target.name;
        this.setState({[item]:''});
		this.props.handleMdsCopyFields(e);
	}
	
	handleCopyAllFields=(e)=>{
		const item = e.target.name;
        this.setState({[item]:''});
		this.props.handleMdsCopyAllFields(e);
	}
	
	/* renderActions(fieldId,note){
		let needHTML = '';
		if(this.props.dataMode == 'TestForm'){
			
			if(this.props.showResponseData){
				let needsNote = this.props.needsNote.get(fieldId);
				if(needsNote !== undefined){
					needHTML = attentHTML(fieldId,needsNote,this.showNeedModal);
				}
				
			}else{
				needHTML = attentHTML(fieldId,note,this.showNeedModal);
			}
			
			return(<div className={this.props.showResponseData ? 'needNote needNicon' : 'col-md-1 needNote'}>
				<div className="row">
					<div className="need-icon">{needHTML}</div>
					{!this.props.showResponseData ?
					<div className="need-checkbox"> <input type="checkbox" name ={fieldId} checked={ note ? 'checked' : this.props.checkedNeeds.get(fieldId)} onChange={this.handleNeedsNote}/></div>
					:null}
				</div>      
			</div>);
		}else{
			return null;
		}			
    } */
	
	renderActions(fieldId,note){
	}
	
	handleChange=(e)=>{
		const item = e.target.name;
		const val = e.target.value;
		const data_id = e.target.id;
		let reviewFieldId = this.state.reviewFieldId;
		$('#box-'+reviewFieldId).hide();
		if(val == 'suggestion'){
			$('#box-'+reviewFieldId).show();
		}
		this.setState({[data_id]:val,reviewRadio:val,['hasReview-'+reviewFieldId]:val});
		this.props.getFormReviewValue(e);
	}
	
	showSuggestion=(fieldId)=>{
		let userReviewData = this.props.userReviewData;
		let reviewData = userReviewData.get(fieldId);
		//console.log('reviewData222->',reviewData);
		if(reviewData){
			this.setState({reviewRadio:reviewData.reviewStatus});
			//$("#reviewCheck-"+fieldId).prop('checked', true);
		}else{
			this.setState({reviewRadio:''});
			$('#box-'+fieldId).hide();
			this.setState({['hasReview-'+fieldId]:''});
			//$("#reviewCheck-"+fieldId).prop('checked', false);
		}
		
		this.setState({reviewFieldId:fieldId});
		//let reviewRadio = this.state.reviewRadio;
		//if(!reviewRadio){
			//this.setState({reviewRadio:'acceptable'});
		//}
		
		$('#review-'+fieldId).modal('show');
		/* let outputText = this.state.outputText;
		if($("input[name='showOutputText']").prop("checked") == true){
			this.setState({outputText:true});
		}else{
			this.setState({outputText:false});
		} */
	}
	
	reviewActions(fieldId,data){
		//let needHTML = '';
		if(this.props.dataMode == 'TestForm' && (this.props.role == 'Administrator' || this.props.role == 'Reviewer')){
			let selectedValue = this.state['rw-'+fieldId];
			let kioskResponse = this.props.kioskResponse;
			let res = kioskResponse.get(fieldId);
			let hasReview = this.state['hasReview-'+fieldId];
			return(<div className={'col-lg-1 col-md-1 col-2 '}>
				<div className="row1">
					<div className="review-checkbox"> <input id={"#reviewCheck-"+fieldId} type="checkbox" name={fieldId} onClick={()=>this.showSuggestion(fieldId)} checked={ hasReview ? 'checked' : ''}/></div>
				</div>
				<div className="modal fade" id={'review-'+fieldId} tabindex="-1">
					<div className="modal-dialog">
						<div className="modal-content">

							<div className="modal-header">
								<h5 className="modal-title">Review Field: {data[4]}</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							
							<div className="modal-body">
								<div className="row">
									<div className="col-md-12">
										<Radio id={'rw-'+fieldId} checked={selectedValue === 'acceptable'} onChange={this.handleChange} value="acceptable" name={'reviewStatus-'+fieldId} size="small" /> Field reviewed and acceptable
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<Radio id={'rw-'+fieldId} checked={selectedValue === 'suggestion'} onChange={this.handleChange} value="suggestion" name={'reviewStatus-'+fieldId} size="small" /> Suggestions for improvement
									</div>
								</div>
								
								<div id={"box-"+fieldId} className="suggestion-box-section">
									<div className="suggestion-box">
										<p><b>Original Field Text:</b> {data[4]}</p>
										<p>Suggested rewording:</p>
										<textarea rows={'2'} className="form-control" name='reVisibleTest' value={this.props.reVisibleTest} onChange={this.props.getFormReviewValue}></textarea>
									</div>
									
									<div className="suggestion-box">
										<p><b>Original output Text:</b> {data[9]}</p>
										<p>Suggested rewording:</p>
										<textarea rows={'2'} className="form-control" name='reOutputText' value={this.props.reOutputText} onChange={this.props.getFormReviewValue}></textarea>
									</div>
									
									<div className="suggestion-box">
										<p><b>Original Tags:</b> {data[13]}</p>
										<p>Suggested rewording:</p>
										<textarea rows={'2'} className="form-control" name='reTags' value={this.props.reTags} onChange={this.props.getFormReviewValue}></textarea>
									</div>
									
									<div className="suggestion-box">
										<p><b>Original response:</b> {data[6]}</p>
										<p>Suggested options as comma delimited or describe:</p>
										<textarea rows={'2'} className="form-control" name='reFieldResponse' value={this.props.reFieldResponse} onChange={this.props.getFormReviewValue}></textarea>
									</div>
									
									<div className="last-suggestion-box">
										<div className="suggestion-box">
											<Checkbox name="reviewAppropriate" value='false' size="small" onChange={this.props.getFormReviewValue} /> This field type is appropriate for the question.
										</div>
										
										<div className="suggestion-box">
											<Checkbox name="reviewBetter" value='false' size="small" onChange={this.props.getFormReviewValue} /> Another field type would be better for the question. ( add your suggestions in the comments box below)
										</div>
										
										<div className="suggestion-box">
											<Checkbox name="reviewPresented" value='false' size="small" onChange={this.props.getFormReviewValue} /> This question could also be presented well by using other field types. ( add your suggestions in the comments box below)
										</div>
										
										<div className="suggestion-box">
											<p>Comments and Suggestions :</p>
											<textarea rows={'3'} className="form-control" name='reviewerComments' value={this.props.reviewerComments} onChange={this.props.getFormReviewValue}></textarea>
										</div>
									</div>
								</div>
								
							</div>
							
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" data-dismiss="modal" onClick={()=>this.props.storeFormReviewData(fieldId)}> Save </button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
			</div>);
			
			
		}else{
			return(<div className={'col-lg-1 col-md-1 col-2 '}></div>);
		}			
    }
	
	needsModal=(needData, needIds)=>{
        if(typeof needIds !== 'undefined' && needIds !== ''){
            return (<div className="modal" id={'needs'+needIds}>
                <div className="modal-dialog">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Needs Attention Note <b>{needIds}</b></h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        <textarea rows={this.props.showResponseData ? '5' : '3'} className="form-control" name={ needIds } value={this.state[needIds]} onChange={this.getValue}></textarea>
                    </div>
                    {needData[27] ?
                        <div className="modal-footer need-attention-footer">
                            <button type="button" className="btn btn-primary mb-2 mt-2" onClick={()=>this.props.removeNeedsNote(needIds)}> Remove Need Note</button>
                            <button type="button" className="btn btn-primary"  data-dismiss="modal" onClick={()=>this.props.saveNeedsNote(needIds,this.state[needIds])} style={{textAlign:'left'}}>Save</button>
                            <button type="button" className="btn btn-danger"  data-dismiss="modal">Close</button>
                        </div>
                    :
                        <div className="modal-footer need-attention-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>this.props.saveNeedsNote(needIds,this.state[needIds])} style={{textAlign:'left'}}>Save</button>
                            <button type="button" className="btn btn-danger" onClick={()=>this.props.saveNeedsNote(needIds,'')}  data-dismiss="modal">Close</button>
                        </div>
                    }
                </div>
                </div>
            </div>)
        }
    }
	
	mdsCopyField=(id,type,guid,alreadycopied)=>{
        if(id && this.props.showFieldCopyView){
			
			let copyAllData = this.props.copyAllData;
			//console.log('copyAllData->',copyAllData);
			let copied = false;
			//$('#'+id).removeClass('filed-copied');
			if(copyAllData.length > 0){
				copyAllData.map((field,i)=>{
					if(guid == field[117]){
						copied = true;
						/* setTimeout(function(){
							$('#'+id).addClass('filed-copied');
						}, 2000); */
					}
				});
			}
			
			let href = window.location.href.split('?')[0];
			
			if(type.toLowerCase() == 'parent header' || type.toLowerCase() == 'child header 1' || type.toLowerCase() == 'child header 2' || type.toLowerCase() == 'child header 3'){
				setTimeout(function(){
					if(alreadycopied){
						$('.headers-'+id).addClass('filed-copied');
					}
				}, 2000);
				if(copied){
					return (<div className="check-field-copy-2"><img src={href+'/copied.png'} width="20px" height="20px"/></div>);
				}else{
					return (<div className="check-field-copy-2"><input type="checkbox" name={id} checked={ this.props.checkedCopyFields.get(id) ? 'checked' : ''} onChange={this.handleCopyAllFields}/></div>);
				}

			}else{
				setTimeout(function(){
					if(alreadycopied){
						$('#'+id).addClass('filed-copied');
					}
				}, 2000);
				if(copied){
					return (<div className="check-field-copied"><img src={href+'/copied.png'} width="20px" height="20px"/></div>);
				}else{
					return (<div className="check-field-copy"><input type="checkbox" name={id} checked={ this.props.checkedCopyFields.get(id) ? 'checked' : ''} onChange={this.handleCopyFields}/></div>);
				}
			}
			
        }
    }
	
	getValue=(event)=>{
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
	
	render() {
        const {sheetData, getPlaceholder, queId, comment, outputText, apiPdfs, kioskResponse, dataMode, needsNote,showFieldCopyView,getFormReviewValue,userReviewData} = this.props;
		return (
		  <div id={queId} className={queId ? 'inner-'+ comment[0] +' pr-0 childMain col-11 offset-1 '+queId+' collapse' : 'comments '+dataMode} >
			{sheetData.map((comment,i) =>
			  <div id={comment[0]} data-pos={comment[10]} key={i}  className={this.classTypes(comment)}>
				{(() => {
					
					let question = comment[4];
					if(outputText){
						question = comment[9] ? comment[9] : comment[4];
					}
					
					
					
					let NoteHTML = noteHtml(comment,comment[12]);
					let NoteModal = noteModal(comment,comment[12]);
					let NeedsModal = this.needsModal(comment, comment[0]);
					let mdsCopyField = '';
					
					if(this.props.showFieldCopyView){
						NoteHTML = '';
						NoteModal = '';
						NeedsModal = '';
						mdsCopyField = this.mdsCopyField(comment[0],comment[5],comment[117],comment[134]);
					}
					
					let answer = generatehtmlfromlocation(comment,this.subQuestions,kioskResponse,apiPdfs,outputText);
					let res = kioskResponse.get(comment[0]);
					let that = this;
					switch (comment[5].toLowerCase()) {
						case 'parent header':
							return <div><h3 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'header-m headers-'+comment[0]}> <i className="fa fa-plus-square-o fa-1x mr-2"></i>{ question } </h3>{mdsCopyField}</div>;
						case 'child header 1':
							return <div className="child-headers">
										<div className="row">
											<div className={'col-md-11'}>
												<h4 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'header-m headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>
													{question}
												</h4>
												{mdsCopyField}
											</div>
											<div className={'col-md-1'}>
												{NoteHTML} {NoteModal}
											</div>
										</div>
									</div>
						case 'child header 2':
							return <div className="child-headers">
									<div className="row">
										<div className={'col-md-11'}>
											<h5 data-target={'.'+comment[1].toLowerCase()} id={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'header-m headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>{question} </h5>
											{mdsCopyField}
										</div>
										<div className={'col-md-1'}>
											{NoteHTML} {NoteModal}
										</div>
									</div>  
							</div>;
						case 'child header 3':
							return <div className="child-headers">
								<div className="row">
									<div className={'col-md-11'}>
										<h6 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'header-m headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>{question} </h6>
										{mdsCopyField}
									</div>
									<div className={'col-md-1'}>
										{NoteHTML} {NoteModal}
									</div>
								</div>
							</div>;
						
						case 'title':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={'col-lg-10 col-md-10 col-10 input-text'}>
									<h5>{question}</h5>
								</div>
								{this.reviewActions(comment[0],comment)}
								<div className={'col-md-1'}>
									{NoteHTML} {NoteModal}
								</div>
								
							</div>
							 
						</div>)
						
						case 'text':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={'col-lg-10 col-md-10 col-10 input-text'}>
									<b>{question}</b>
								</div> 
								{this.reviewActions(comment[0],comment)}
								<div className={'col-md-1'}>
									{NoteHTML} {NoteModal}
								</div>
							</div>
							 
						</div>)
						
						case 'link':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={'col-lg-10 col-md-10 col-10 input-text'}>
									{answer}
								</div>
								{this.reviewActions(comment[0],comment)}
								<div className={'col-md-1'}>
									{NoteHTML} {NoteModal}
								</div>
							</div>
							 
						</div>)
						
						case 'q13' :
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={'col-lg-10 col-md-10 col-10 answer'}>
									{ answer }
								</div>
								{this.reviewActions(comment[0],comment)}
								<div className={'col-md-1'}>
									{NoteHTML} {NoteModal}
								</div>
							</div>
							 
						</div>)
						
						//return <FieldQ13 fieldId={comment[0]} required={comment[15]} buttonLabel={comment[40]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} />
						
						/* case 'launchpad2' :
						return <Launchpad fieldId={comment[0]} required={comment[15]} buttonLabel={comment[40]} fieldGuid={comment[117]} url={comment[78]} type={comment[138]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} /> */
						
						/* case 'image3' :
						return <FieldInlineImage fieldId={comment[0]} data={comment[99]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} /> */
						
						/* case 'inlinevideo' :
						return <FieldInlineVideo fieldId={comment[0]} data={comment[99]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} /> */
						
						/* case 'pdfapi' :
						return  <FieldPdfApi fieldId={comment[0]} required={comment[15]} data={apiPdfs} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} func={this.subQuestions} /> */
						
						case 'assessment' :
						return  <FieldAssessment fieldId={comment[0]} required={comment[15]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} />
						
						case 'subjective' :
						return  <FieldSubjective fieldId={comment[0]} required={comment[15]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} />
						
						case 'begin':
							return (<div className="full-width"></div>)
							
						/* case 'calculation':
						case 'wcalculation':
						return  <FieldCalculation fieldId={comment[0]} question={question} />
						 */
						case 'checklist':
						return  <Checklist fieldId={comment[0]} required={comment[15]} fieldGuid={comment[117]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} outputText={outputText}/>
							
						case 'end':
						return (<div className="full-width"></div>)
						
						case 'calculation':
						case 'wcalculation':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={'col-lg-10 col-md-10 col-10 input-text'}>
									{ answer }
								</div>
								{this.reviewActions(comment[0],comment)}
								<div className={'col-md-1'}>
									{NoteHTML} {NoteModal}
								</div>
							</div>
							 
						</div>)
						
						case 'image' :
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={'col-lg-10 col-md-10 col-10 input-text'}>
									{ answer }
								</div>
								{this.reviewActions(comment[0],comment)}
								<div className={'col-md-1'}>
								</div>
							</div>
							<div className="row">
								<div className={'col-md-10 answer'}>
									{question}
								</div>  
								<div className={'col-md-1'}>
									{NoteHTML} {NoteModal}
								</div>
							</div> 
						</div>)
						
						case 'inlinevideo' :
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={'col-lg-10 col-md-10 col-10 input-text'}>
									{ answer }
								</div>
								{this.reviewActions(comment[0],comment)}
							</div>
							<div className="row">
								<div className={'col-md-10 answer'}>
									{question}
								</div>
								<div className={'col-md-1'}>
									{NoteHTML} {NoteModal}
								</div>
							</div> 
						</div>)
						
						case 'q5':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={'col-lg-10 col-md-10 col-10 input-text'}>
									 { answer } <div className="question-q5">{question}{ comment[15] == 'Y' ? <span className="required">*</span> : ''}</div>
								</div>
								{this.reviewActions(comment[0],comment)}
								<div className={'col-md-1'}>
									{NoteHTML} {NoteModal}
								</div>
							</div>
							 
						</div>)
						
						case 'q10':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={'col-lg-10 col-md-10 col-10 input-text'}>
									{this.getQueType(comment[5].toLowerCase(),comment[0],res)} {question} { comment[15] == 'Y' ? <span className="required">*</span> : ''}
								</div>
								{this.reviewActions(comment[0],comment)}
								<div className={'col-md-1'}>
									{NoteHTML} {NoteModal}
								</div>
							</div>
							<div className="row">
								<div className={'col-md-10 answer'}>
									{ answer }
								</div>   
							</div> 
							 
						</div>)
						
						case 'sig':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={'col-lg-10 col-md-10 col-10 input-text'}>
									{this.getQueType(comment[5].toLowerCase(),comment[0])} {question} { comment[15] == 'Y' ? <span className="required">*</span> : ''}
								</div>
								{this.reviewActions(comment[0],comment)}
								<div className={'col-md-1'}>
									{NoteHTML} {NoteModal}
								</div>
							</div>
							<div className="row">
								<div className={'col-md-10 answer'}>
									{ answer }
								</div>   
							</div> 
							 
						</div>)
						
						default:
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={'col-lg-10 col-md-10 col-10 input-text'}>
									{question} { comment[15] == 'Y' ? <span className="required">*</span> : ''}
								</div>
								{this.reviewActions(comment[0],comment)}
								{/* <div className={'col-md-2'}>
									{NoteHTML} {NoteModal} {NeedsModal} {mdsCopyField}
								</div> */}

								<div className={'col-md-1'}>
									{NoteHTML} {NoteModal}
								</div>
								
							</div>
							<div className="row">
								<div className={'col-md-10 answer'}>
									{ answer }
								</div>
							</div> 
						</div>)                         
					}
				})()}
				{comment.sub 
					&& 
					<MainData 
						sheetData={comment.sub} 
						queId={comment[1].toLowerCase()}  
						comment= {comment}
						outputText= {this.props.outputText}
						showFieldCopyView= {this.props.showFieldCopyView}
						kioskResponse= {kioskResponse}
						needsNote= {needsNote}
						checkedNeeds= {this.props.checkedNeeds}
						handleNeeds= {this.props.handleNeeds}
						checkedCopyFields= {this.props.checkedCopyFields}
						handleMdsCopyFields= {this.props.handleMdsCopyFields}
						handleMdsCopyAllFields= {this.props.handleMdsCopyAllFields}
						allData= {this.props.allData}
						copyAllData= {this.props.copyAllData}
						apiPdfs= {this.props.apiPdfs}
						dataMode= {this.props.dataMode}
						loadDataSet= {this.props.loadDataSet}
						showResponseData= {this.props.showResponseData}
						delegateUsers= {this.props.delegateUsers}
						delegateFilter= {this.props.delegateFilter}
						getResponseValue={this.props.getResponseValue}
						saveNeedsNote={this.props.saveNeedsNote}
						removeNeedsNote = {this.props.removeNeedsNote}
						getFormReviewValue = {this.props.getFormReviewValue}
						storeFormReviewData = {this.props.storeFormReviewData}
						userReviewData = {this.props.userReviewData}
						role = {this.props.role}
						responseSaved = {this.props.responseSaved}
					/>
				}
			  </div>
			)}
		  </div>
		)
    }
}