import React, { Component } from 'react';
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import Recurring from "./Recurring";
import { CSVLink } from "react-csv";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, TextField, Table, TableSortLabel, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

export default class MongoDBLayoutManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			layoutFilesVersion:new Map(),
			layoutMdsFiles:new Map(),
			layoutFriendlyName:new Map(),
			selectedFiles:new Map(),
			filesExist:false,
			daysCount:0,
			page:0,
			rowsPerPage:10,
			filterMode:'',
			searchQuery:'',
			childSheets:[],
		}
    }
	
	componentDidMount() {
		let that = this;
		setTimeout(function(){
			that.filterMdsForms();
		}, 3000);
		setInterval(this.checkFiles, 2000);
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		let that = this;
		if(name == 'filterMode'){
			setTimeout(function(){
				that.filterMdsForms();
			}, 1000);
		}
		
		if(name == 'selectedInstance'){
			this.props.getValue2(event);
			setTimeout(function(){
				that.setState({childSheets:that.props.childSheets});
				that.getLayoutJsonFileVersion();
			}, 3000);
		}
	}
	
	filterMdsForms=()=>{
		let childSheets = this.props.childSheets;
		let layoutForms = [];
		childSheets.map((sheet,i)=>{
			if(this.state.filterMode && this.state.filterMode != sheet.Mode){
				return false;
			}
			layoutForms.push(sheet);
		});
		
		let searchQuery = this.state.searchQuery;
		if(searchQuery){
			layoutForms = layoutForms.filter((row) =>
				row.LayoutName.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.Mode.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.Version && row.Version == searchQuery ||
				//row.visibleTxt && row.visibleTxt.toLowerCase().includes(searchQuery.toLowerCase()) ||
				//row.outputTxt && row.outputTxt.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.Types && row.Types.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.DeviceTypes && row.DeviceTypes.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.VisitTypes && row.VisitTypes.toLowerCase().includes(searchQuery.toLowerCase())
			);
		}
		this.setState({childSheets:layoutForms});
	}
	
	checkFiles=()=>{
		if(this.state.filesExist == false){
			this.getLayoutJsonFileVersion();
			if(this.props.childSheets){
				this.props.childSheets.map((sheet,i)=>{
					if(sheet.Mode == 'Build' && sheet.Mode != 'Publish'){
						this.checkUserAssignedForm(sheet.LayoutGuid);
					}
				});
			}
		}
	   
		if(this.props.childSheets){
		    this.setState({filesExist:true});
		}
	}
	
	checkUserAssignedForm=(LayoutGuid)=>{
		let ApiUrl = config.mongoApiUrl;
		let url = ApiUrl+'layout-user/'+LayoutGuid;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			const mode = response.data;
			this.setState({['mode-'+LayoutGuid]:mode});
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	uploadLayoutInMongo=()=>{
		let selectedFiles = this.state.selectedFiles;
		let files = Object.fromEntries(selectedFiles.entries());
		let layouts = [];
		for (let prop in files) {
			layouts.push(files[prop]);
		}
		layouts.map((sheetName,i)=>{
			this.uploadLayoutJsonMongoDb(sheetName);
		});
		
	}
	
	uploadMdsJsonMongoDb =(sheetName)=> {
		let ApiUrl = config.mongoApiUrl;
		let url = ApiUrl+'mds-file/'+sheetName;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
            const allData = response.data.Fields;
			let mode = response.data.Mode ? response.data.Mode : 'Test';
			let currentDate = new Date().toLocaleString("en-US").replace(',','');
			let parentMds = [];
			if(allData){
				let mdsGuid = allData[1][91];
				let version = allData[1][59] ? allData[1][59] : '1.01';
				
				let dates = [];
				let fields = [];
				allData.map((field,i)=>{
					if(i > 0){
						
						let date = new Date(field[98]);
						if(date != 'Invalid Date'){
							dates.push(date);
						}
						
						let notes = field[12] = field[12].replaceAll('\n','');
						notes = notes.replaceAll('<p></p>','');
						let customNotes = field[116] = field[116].replaceAll('\n','');
						customNotes = customNotes.replaceAll('<p></p>','');
						
						let preId = '';
						let parentGuid = '';
						allData.map((pre_field,i)=>{
							if(field[7] && field[7] == pre_field[0]){
								preId = pre_field[117];
							}
							
							if(pre_field[1] == field[2]){
								parentGuid = pre_field[117];
							}
						})
						let delegateTypes = this.props.delegateTypes;
						let kioskT = [];
						delegateTypes.map((val,i)=>{
							let fieldk = val.split(':');
							let fd = {
								['ID']: parseInt(i+1),
								['delegate']: fieldk[1].trim(),
							}
							kioskT.push(fd);
						});

						field[91] = '';
						field[129] = '';
						field[130] = '';
						field[131] = '';
						
						let rqFields = [];
						let requiredFields = '';
						if(field[142] && field[5] == 'launchpad'){
							let rfStr = field[142].split(',');
							rfStr.map((rfield,i)=>{
								rfield = rfield.split('|');
								rqFields.push(rfield[0]+','+rfield[1]);
							});
							requiredFields = rqFields.join('|');
						}
						
						let opFields = [];
						let optionalFields = '';
						if(field[143] && field[5] == 'launchpad'){
							let opStr = field[143].split(',');
							opStr.map((ofield,i)=>{
								ofield = ofield.split('|');
								opFields.push(ofield[0]+','+ofield[1]);
							});
							optionalFields = opFields.join('|');
						}
						let createdDate = new Date(field[97]).toLocaleString("en-US").replace(',','');
						let lastDate = new Date(field[98]).toLocaleString("en-US").replace(',','');
						
						let options = [];
						if(field[6]){
							options = field[6].split(',');
						}
						
						let dateValidation = '';
						if(field[48] == 'V9'){
							dateValidation = 'past'
						}else if(field[48] == 'V10'){
							dateValidation = 'today'
						}else if(field[48] == 'V11'){
							dateValidation = 'future'
						}
						
						let gridLabel = [];
						if(field[5] == 'basicgrid' || field[5] == 'grid5point' || field[5] == 'grid-multicheck' || field[5] == 'grid-singlecheck'){
							let str = field[41] ? field[41].split('_') : [];
							if(str.length > 0){
								let columns = str[0].split(',');
								let clabel = [];
								columns.map((val,i)=>{
									//let fieldN = 'columnlabel'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['label']: val.trim(),
										['type']: field[5],
									}
									clabel.push(fd);
								});
								
								let rows = str[1].split(',');
								let crow = [];
								rows.map((val,i)=>{
									//let fieldN = 'rowlabel'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['label']: val.trim(),
										['type']: field[5],
									}
									crow.push(fd);
								});
								gridLabel = {
									"ColumnLabelValues": clabel,
									"RowLabelValues": crow,
								}
							}
						}
						
						if(field[5] == 'basicgrid2'){
							let str = field[41] ? field[41].split('_') : [];
							if(str.length > 0){
								let columns = str[0].split('|');
								let rows = str[1].split(',');
								let crow = [];
								rows.map((val,i)=>{
									//let fieldN = 'rowlabel'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['label']: val.trim(),
										['type']: field[5],
									}
									crow.push(fd);
								});
								
								let columnslable = [];
								let columnstd = [];
								columns.map((val,i)=>{
									let cm = val.split(':');
									//let fieldN = 'columnlabel'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['label']: cm[0].trim(),
										['type']: field[5],
									}
									
									columnslable.push(fd);
									columnstd.push(cm[1]);
								});
								let columnstd2 = [];
								columnstd.map((val,i)=>{
									let fieldm = 'column'+parseInt(i+1);
									let fds = {
										['ID']: parseInt(i+1),
										['option']: val.trim(),
									}
									columnstd2.push(fds);
								});
								
								gridLabel = {
									"ColumnLabelValues": columnslable,
									"RowLabelValues": crow,
									"ColumnDataType": columnstd2,
								}
							}
						}
						
						let optionValue = [];
						if(field[5] == 'weighted'){
							let str = field[41] ? field[41].split(',') : [];
							str.map((val,i)=>{
								let op = val.split(':');
								options.push(op[0]);
								optionValue.push(op[1]);
							});
						}
						
						if(field[5] == 'grid-dropdown'){
							let str = field[41] ? field[41].split('_') : [];
							
							if(str.length > 0){
								let columns = str[0].split(',');
								let clabel = [];
								columns.map((val,i)=>{
									//let fieldN = 'columnlabel'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['label']: val.trim(),
										['type']: field[5],
									}
									clabel.push(fd);
								});
								let rows = str[1].split('|');
								
								//let columnslable = [];
								let rowValue = [];
								let coption = [];
								rows.map((val,i)=>{
									let cm = val.split(':');
									//columnslable.push(cm[0]);
									//rowValue.push(cm[0]);
									let fieldN = 'rowlabel'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['label']: cm[0].trim(),
										['type']: field[5],
									}
									rowValue.push(fd);
									cm.map((val,j)=>{
										if(j > 0){
											let fieldN = 'row'+parseInt(i+1)+'_columnoption'+parseInt(j);
											let fd = {
												['ID']: parseInt(j+1),
												['option']: val.split(','),
											}
											coption.push(fd);
										}
									});
								});
								
								gridLabel = {
									"ColumnLabelValues": clabel,
									"RowLabelValues": rowValue,
									"ColumnOption": coption,
									//"ColumnDataType": columnstd,
								}
							}
						}
						
						let ctype = field[114];
						let ctypeVal = [];
						let calculationType = [];
						if(field[5] == 'calculation'){
							if(ctype.indexOf(',') > -1) {
								let ctypearr = ctype.split(',');
								ctypearr.map((val,i)=>{
									//let fieldN = 'field'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['field']: val.trim(),
									}
									ctypeVal.push(fd);
								});
							}
							calculationType = ["Sum", "Average", "Subtract", "Highest Number", "Lowest Number", "Longest Time", "Shortest Time"]
						}
						
						let opVal = [];
						if(options.length > 0){
							options.map((val,i)=>{
								//let fieldN = 'option'+parseInt(i+1);
								let fd = {
									['ID']: parseInt(i+1),
									['option']: val.trim(),
								}
								opVal.push(fd);
							});
						}

						let FieldTypeOptions = {
							"Options": opVal.length > 0 ? opVal : '',
							//"OptionsValue": optionValue.length > 0 ? optionValue : '',
							"GridColumns": field[136],
							"GridRows": field[137],
							"GridLabelValue": Object.keys(gridLabel).length !== 0 ? gridLabel : '',
							"CalculationType": field[113],
							//"CalculationType": calculationType.length > 0 ? calculationType : '',
							"CalculationFields": ctypeVal.length > 0 ? ctypeVal : '',
							"WebsiteLink": field[78],
							//"TrackingCode": field[125] ? field[125].split(',') : '',
							//"TrackingCode": TrackingCode.length > 0 ? TrackingCode : '',
							//"DateValidation": dateValidation.length > 0 ? dateValidation : '',
							"DateValidation": dateValidation,
						}
						
						if(field[5] == 'slider'){
							let str = field[41].split(',');
							if(str.length > 1){
								options = {
									"LabelLeft": str[0],
									"LabelRight": str[1],
								}
							}
						
							FieldTypeOptions['Options'] = options;
							FieldTypeOptions['StepValue'] = field[36];
							FieldTypeOptions['MinValue'] = field[50];
							FieldTypeOptions['MaxValue'] = field[51];
						}
						
						const filteredObj2 = (obj) =>
						  Object.entries(obj)
							.filter(([_, value]) => !!value || typeof value === "boolean")
							.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
						
						FieldTypeOptions = filteredObj2(FieldTypeOptions);
						
						let DataLowLimit = '';
						let DataHighLimit = '';
						if(field[5] == 'Q6'){
							DataLowLimit = {
								'number':field[50],
							}
							
							DataHighLimit = {
								'number':field[51],
							}
						}
						
						if(field[5] == 'timer'){
							DataLowLimit = {
								'time':field[50],
							}
							
							DataHighLimit = {
								'time':field[51],
							}
						}
						
						let fieldData = {
							"FieldGUID": field[117],
							"MasterDataSetGUID": mdsGuid,
							"FieldName": field[0].replaceAll("<<", "").replaceAll(">>", "").trim(),
							"FieldNameIdentifier_STATIC": field[61],
							"VisibleText": field[4],
							"FieldType": field[5],
							"FieldTypeOptions": Object.keys(FieldTypeOptions).length !== 0 ? FieldTypeOptions : '',
							"PrefilteredDelegates": kioskT.length > 0 ? kioskT : '',
							"ButtonLabel": field[40],
							"ImageLocationReferenceInfo": field[99],
							"OutputText": field[9],
							"Notes": notes,
							"InternalDataPersistenceLabel": field[110],
							"Tags": field[13],
							"DataAgg": field[3] == 'Y' ? true:false,
							"AutoTags": field[106] ? field[106].replaceAll("<<", "").replaceAll(">>", "").trim() : '',
							"ValidationType": field[48],
							"DataLowLimit": field[5] != 'Q8' ? DataLowLimit : '',
							"DataHighLimit":field[5] != 'Q8' ? DataHighLimit : '',
							"CreatedDate": field[97] ? moment(createdDate).format('YYYY-MM-DD HH:mm:ss') : moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
							"ModifiedDate": moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
							"IsActive": field[60] == 'Y' || field[60] == '' ? true:false,
							"Translations": false,
						}
						
						const filteredObj = (obj) =>
						  Object.entries(obj)
							.filter(([_, value]) => !!value || typeof value === "boolean")
							.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
						
						fieldData = filteredObj(fieldData);
						fields.push(fieldData);
						
					}
				})

				let maximumDate = new Date(Math.max.apply(null, dates));
				let minimumDate = new Date(Math.min.apply(null, dates));
				let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
				let minDate = new Date(minimumDate).toLocaleString("en-US").replace(',','');
				
				parentMds = parentMds.filter(function(x) {
					 return x !== undefined;
				});
				let parentMdsGuid = this.uniqueArray2(parentMds);
				let itemString = parentMdsGuid.toString();
				
				let layout ={
					"MDSGuid": mdsGuid,
					"MDSName": sheetName,
					"CreatedDate": moment(minDate).format('YYYY-MM-DD HH:mm:ss'),
					"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
					'Version': version,
					"Mode": mode,
					'Fields': fields,
				}
				this.insertMdsJsonMongo(JSON.stringify(layout));
			}

		},function(reason) {
			alert(reason.result.error.message);
		});
	}
	
	uploadLayoutJsonMongoDb = (sheetName) => {
		
		let ApiUrl = config.mongoApiUrl;
		let url = ApiUrl+'layout-file/'+sheetName;
		let data = [];
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
            const data = response.data;
			let currentDate = new Date().toLocaleString("en-US").replace(',','');
			let parentMds = [];
			let allData = data.Fields;
			let mode = data.Mode ? data.Mode : 'Build';
			let version = data.Version ? data.Version : '1.01';
			if(allData.length > 0){
				let layoutGuid = allData[1][91];
				let associatedType = allData[1][92];
				let associatedForm = allData[1][93];
				let associatedDevice = allData[1][94];
				let associatedVisit = allData[1][95];
				//let version = allData[1][59] ? allData[1][59] : '1.01';
				let friendly_name = allData[1][62] ? allData[1][62] : '';
				let Public = allData[1][111] ? allData[1][111] : 'Yes';
				let PrivateLayoutOwner = allData[1][115] ? allData[1][115] : '';
				let AssignedTrack = allData[1][125] ? allData[1][125].split(',') : '';
				
				let dates = [];
				let fields = [];
				let mdsFiles = [];
				allData.map((field,i)=>{
					if(i > 0){
						let date = new Date(field[98]);
						if(date != 'Invalid Date'){
							dates.push(date);
						}
						
						parentMds.push(field[129]);
						
						mdsFiles.push(field[118]);
								
						let preId = '';
						let parentGuid = '';
						allData.map((pre_field,i)=>{
							if(field[7] && field[7] == pre_field[0]){
								preId = pre_field[117];
							}
							
							if(pre_field[1] == field[2]){
								parentGuid = pre_field[117];
							}
						})

						let oldP = field[16];
						let userArray = oldP.split(',');
						userArray.splice(0, 9);
						
						let uArray = userArray.filter(function(v){return v!==''});
						let kioskTypes = uArray.toString();
						kioskTypes = kioskTypes.replaceAll(',','|');

						
						let fieldData = {
							"FieldGUID": field[131] ? field[131] : field[117],
							"Position#": field[10],
							"Include": field[14] == 'Y' ? true:false,
							"Required": field[15] == 'Y' ? true:false,
							"DelegateTypes": "["+kioskTypes+"]",
							"DefaultLayoutGuid": layoutGuid,
							"ParentGUID": parentGuid,
							"PostMergeEdit": field[17] ? field[17] : 'Yes',
							//"Public": field[111] == 'Y' ? true:false,
						}
						
						const filteredObj = (obj) =>
						  Object.entries(obj)
							.filter(([_, value]) => !!value || typeof value === "boolean")
							.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
						
						fieldData = filteredObj(fieldData);
						fields.push(fieldData);
						
					}
				})

				let minimumDate = new Date(Math.min.apply(null, dates));
				let maximumDate = new Date(Math.max.apply(null, dates));
				let minDate = new Date(minimumDate).toLocaleString("en-US").replace(',','');
				let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
				parentMds = parentMds.filter(function(x) {
					 return x !== undefined;
				});
				
				let parentMdsGuid = this.uniqueArray2(parentMds);
				
				let mdsFilesArray = this.uniqueArray2(mdsFiles);
				
				if(mdsFilesArray.length > 0){
					mdsFilesArray.map((fls,i)=>{
						this.uploadMdsJsonMongoDb(fls);
					});
				}
				
				let itemString = parentMdsGuid.toString();
				
				let layout ={
					"DefaultLayoutName": sheetName,
					"DefaultLayoutGuid": layoutGuid,
					"FriendlyName": friendly_name,
					"Public": Public,
					"PrivateLayoutOwner": PrivateLayoutOwner,
					"IncludedMDSGUIDs": itemString,
					"AssociatedType": associatedType,
					"AssociatedDeviceTypes": associatedDevice,
					"AssociatedVisitTypes": associatedVisit,
					"AssignedTrack": AssignedTrack,
					//"AssociatedTrack": [],
					"Version": version,
					"CreatedDate": moment(minDate).format('YYYY-MM-DD HH:mm:ss'),
					"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
					"Mode": mode,
					"showInTemplate": false,
					"Fields": fields,
				}
				//console.log('layout->',layout);
				this.insertLayoutJsonMongo(JSON.stringify(layout));
			}
				
		})
	}
	
	insertMdsJsonMongo(data){
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'insert-mds-v2';
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			console.log('response::', response.data);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	insertLayoutJsonMongo(data){
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'insert-layout-v2';
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			//console.log('response::', response.data);
			let that = this;
			setTimeout(function(){
				that.getLayoutJsonFileVersion();
			}, 1000);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	uniqueArray2(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	
	setUploadToMongo=(event)=>{
		let sheet = event.target.name;
		let type = event.target.id;
		let selectedFiles = this.state.selectedFiles;
		
		if($("#"+type).prop("checked") == true){
			selectedFiles.set(type,sheet);
			this.setState({[type]:true});
		}else{
			selectedFiles.delete(type);
			this.setState({[type]:false});
		}
		
		this.setState({selectedFiles});
	}
	
	getLayoutJsonFileVersion=()=>{
		let layoutSheetGuids = [];
		if(this.props.childSheets){
			this.props.childSheets.map((sheet,i)=>{
				layoutSheetGuids.push(sheet.LayoutGuid);
			});
		}
		if(layoutSheetGuids.length > 0){
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'layout-json-version-v2';
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(layoutSheetGuids),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			
			let files = response.data;
			//console.log('files->',files);
			files.map((file,i)=>{
				if(file.Version){
					let layoutFilesVersion = this.state.layoutFilesVersion;
					layoutFilesVersion.set(file.DefaultLayoutGuid,file.Version);
					
					let layoutMdsFiles = this.state.layoutMdsFiles;
					layoutMdsFiles.set(file.DefaultLayoutGuid,file.mdsFiles);
					
					let layoutFriendlyName = this.state.layoutFriendlyName;
					layoutFriendlyName.set(file.DefaultLayoutGuid,file.LayoutFriendlyName);
					
					this.setState({layoutFilesVersion,layoutMdsFiles,layoutFriendlyName});
				}
			});
			
		}).catch(error => {
			console.log('error::', error);
		})
		}
	}
	
	changeFileMode=(event)=>{
		let value = event.target.value;
		let guid = event.target.id;
		
		let sheetName = $('#ln-'+guid).val();
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'update-layout-mode';
		let data = {
			"LayoutGuid": guid,
			"Mode": value,
		}
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			
			let logData = [];
			this.state.childSheets.map((sheet) =>{
				if(sheet.LayoutGuid == guid){
					logData = {
						"Form": 'Layout',
						"FormGuid": guid,
						"FormName": sheet.LayoutName,
						"Status": value,
						"Action": 'Status Changed',
						"Created": moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
					}
				}
			})
			this.props.auditLog(logData);
			
			//if(value == 'Prod'){
				let that = this;
				that.props.getLayoutSheets(that.props.selectedInstanceId);
				setTimeout(function(){
					that.filterMdsForms();
					that.getLayoutJsonFileVersion();
				}, 3000);
			//}
			
			this.setState({['mode-'+guid]:value});
			//console.log('response::', response);
		}).catch(error => {
			console.log('error::', error);
		})

	}
	
	/* changeMDSFileMode=(value,guid)=>{
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'update-Mds-mode';
		let data = {
			"MDSGuid": guid,
			"Mode": value,
		}
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			//console.log('response::', response);
		}).catch(error => {
			console.log('error::', error);
		})

	} */
	
	deleteLayout=(sheetGuid)=>{
		//alert(sheetGuid);
		let ApiUrl = config.mongoApiUrl;
		let url = ApiUrl+'delete-layout/'+sheetGuid;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			alert('The Layout deleted successfully!');
		}).catch(error => {
			console.log('error::', error);
		})
			
	}
	
	handleChangePage = (event: unknown, newPage: number) => {
		this.setState({page:newPage});
	};

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage:event.target.value,page:0});
	};
	
	handleSort = column => {
		const { sortBy, sortOrder } = this.state;
		const isAsc = sortBy === column && sortOrder === 'asc';
		this.setState({
		  sortBy: column,
		  sortOrder: isAsc ? 'desc' : 'asc'
		});
	};
	
	handleSearch = (event) => {
		let that = this;
		this.setState({searchQuery:event.target.value});
		setTimeout(function(){
			that.filterMdsForms();
		}, 1000);
		
    };
	
	render() {
		
		const {childSheets,layoutFilesVersion,layoutFriendlyName,layoutMdsFiles,selectedFiles,sortBy,sortOrder,searchQuery} = this.state;
		let MdsFiles =[];
		let totalFieldCountM = 0;
		let totalFieldCountC = 0;
		
		const StyledTableCell = styled(TableCell)(({ theme }) => ({
		  [`&.${tableCellClasses.head}`]: {
			color: '#000;',
			padding: '20px 5px;',
			fontWeight: 600,
			fontSize: 15,
		  },
		  [`&.${tableCellClasses.body}`]: {
			fontSize: 16,
			backgroundColor: '#ffffff',
		  },
		}));
		
		const sortedData = [...childSheets].sort((a, b) => {
		  const isAsc = sortOrder === 'asc';
		  if (a[sortBy] < b[sortBy]) {
			return isAsc ? -1 : 1;
		  }
		  if (a[sortBy] > b[sortBy]) {
			return isAsc ? 1 : -1;
		  }
		  return 0;
		});
		
		let optionInstance = '';
		if(this.props.instances){
			let ins = this.props.instances;
			optionInstance = ins.map(function(val,i) {
				return (
						<MenuItem value={val.InstanceGuild} key={i}>{val.InstanceName}</MenuItem>
					);
			})
		}
		
		return (
			<div className="mds-manager-mongo custom-container">
				<div className="mongo-header">
					<div className="mg-page-title"><h4>MongoDB Layout Manager</h4></div>
					
					<div className="mg-upload-all"><button type="button" className="btn btn-info" onClick={this.uploadLayoutInMongo} >Upload selected files</button></div>
					<div className="wp-mode-filter">
						<FormControl sx={{ m: 1, minWidth: 155, maxWidth: 205 }} size="small">
							<InputLabel>Select Mode</InputLabel>
							<Select
								value={this.state.filterMode ? this.state.filterMode : ""}
								name={'filterMode'}
								onChange={this.getValue}
								input={<OutlinedInput label="Select Mode" />}
							>
							<MenuItem value={''} >Select Mode</MenuItem>
							<MenuItem value={'Build'} >Build</MenuItem>
							<MenuItem value={'Test'} >Test</MenuItem>
							<MenuItem value={'Dev-Prod'} >Dev-Prod</MenuItem>
							<MenuItem value={'Prod'} >Prod</MenuItem>
							
							</Select>
						</FormControl>
					</div>
					
					<div className="wp-mode-filter">
						<FormControl sx={{ m: 1, minWidth: 235, maxWidth: 235 }} size="small">
							<InputLabel>Select Instance</InputLabel>
							<Select
								value={this.props.selectedInstanceId}
								name={'selectedInstance'}
								onChange={this.getValue}
								input={<OutlinedInput label="Select Instance" />}
							>
							{optionInstance}
							</Select>
						</FormControl>
					</div>
					
					<div className="wp-search-filter">
							<TextField
								label="Search"
								size="small"
								variant="outlined"
								fullWidth
								value={searchQuery}
								onChange={this.handleSearch}
								style={{ width: '200px' }}
							/>
							<i className="fa fa-search search-icon" aria-hidden="true"></i>
					</div>
				</div>
				{childSheets ?
				<div className="mg-child-sheets">
				<TableContainer>
				  <Table size="small" aria-label="a dense table">
					<TableHead>
					  <TableRow key={11}>
						<StyledTableCell>
						<TableSortLabel
							active={sortBy === 'LayoutName'}
							direction={sortOrder}
							onClick={() => this.handleSort('LayoutName')}
						>
						Layout Name
						</TableSortLabel>
						</StyledTableCell>
						<StyledTableCell>
						<TableSortLabel
							active={sortBy === 'ModifiedDate'}
							direction={sortOrder}
							onClick={() => this.handleSort('ModifiedDate')}
						>
						Last Updated
						</TableSortLabel>
						</StyledTableCell>
						<StyledTableCell>Mode</StyledTableCell>
						<StyledTableCell>Select to process</StyledTableCell>
						<StyledTableCell>Save as F2</StyledTableCell>
						<StyledTableCell>Layout F2</StyledTableCell>
						<StyledTableCell>Ver.#</StyledTableCell>
						<StyledTableCell>MDS F2</StyledTableCell>
						
						<StyledTableCell>PostGres-Dev</StyledTableCell>
						<StyledTableCell>PostGres-Prod</StyledTableCell>
						<StyledTableCell>PostGres Ver.#</StyledTableCell>
						<StyledTableCell>Prod Form Count</StyledTableCell>
						
						<StyledTableCell>&nbsp;</StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					  {sortedData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((sheet) => {
						if(this.state.filterMode && this.state.filterMode != sheet.Mode){
							return false;
						}						
						
						let sheetGuid = sheet.LayoutGuid;
						let mongoVersion = layoutFilesVersion.get(sheetGuid);
						
						let mdsClass = 'not-in-mongo';
								
						let mdsFilestr = '';
						if(layoutMdsFiles.get(sheetGuid) != undefined){
							let MdsFiles = layoutMdsFiles.get(sheetGuid);
							mdsClass = 'has-in-mongo';
							MdsFiles.map((msheet, i) => {
								if(msheet.has == false){
									mdsClass = 'not-in-mongo';
								}
							});
							
							mdsFilestr = MdsFiles.map((msheet, i) => {
								return (
									<tr><td>{msheet.MDSName}</td><td>{msheet.MDSGuid}</td><td>{msheet.Version}</td></tr>
								);
							})
						}
								
						let classn = 'not-in-mongo';
					
						if(mongoVersion && (mongoVersion == sheet.Version || sheet.Version == '')){
							classn = 'has-in-mongo';
						}
						let modeValue = sheet.Mode;
								
						let mState = this.state['mode-'+sheet.LayoutGuid];
						if(mState){
							modeValue = mState;
						}
						
						let lastUpdate = new Date(sheet.ModifiedDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
						return (<TableRow key={sheet.LayoutGuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <StyledTableCell>{sheet.LayoutName}</StyledTableCell>
						  <StyledTableCell>{lastUpdate}</StyledTableCell>
						  <StyledTableCell style={{width: '135px'}}>
							<select id={sheet.LayoutGuid} className="form-control" value={modeValue} onChange={this.changeFileMode}>
								<option value="Build">Build</option>
								<option value="Test">Test</option>
								<option value="Dev-Prod">Dev-Prod</option>  
								<option value="Prod">Prod</option>
							</select>
						  </StyledTableCell>
						  <StyledTableCell><input type="Hidden" value={sheet.LayoutName} id={'ln-'+sheetGuid} /><input type="checkbox" name={sheet.LayoutName} id={sheetGuid} checked={classn == 'has-in-mongo' || this.state[sheetGuid] ? 'checked' : ''} onChange={this.setUploadToMongo} disabled={classn == 'has-in-mongo' ? 'disabled' : ''} /></StyledTableCell>
						  <StyledTableCell><i className="fa fa-floppy-o" aria-hidden="true" onClick={() => { this.uploadLayoutJsonMongoDb(sheet.LayoutName) } }></i></StyledTableCell>
						  <StyledTableCell><i id={'guid-'+sheetGuid} className={"fa fa-circle "+classn} aria-hidden="true"></i></StyledTableCell>
						  <StyledTableCell><i>{sheet.Version ? sheet.Version : '1.01' }</i></StyledTableCell>
						  <StyledTableCell>
							{mdsClass == 'has-in-mongo' ? 
								<div className="mg-mongo-val pointer" data-target={"#layoutDataModal"+sheetGuid} data-toggle="modal"><i id={'guid-'+sheetGuid} className={"fa fa-circle has-in-mongo"} aria-hidden="true"></i></div>
							:
								<div className="mg-mongo-val" data-toggle="modal"><i id={'guid-'+sheetGuid} className={"fa fa-circle not-in-mongo"} aria-hidden="true"></i></div>
							}
						  </StyledTableCell>
						  
						  <StyledTableCell>
							<div className="mg-mongo-val" ><i id={'guid-'+sheetGuid} className={"fa fa-circle not-in-mongo"} aria-hidden="true"></i></div>
						  </StyledTableCell>
						  <StyledTableCell>
							<div className="mg-mongo-val" ><i id={'guid-'+sheetGuid} className={"fa fa-circle not-in-mongo"} aria-hidden="true"></i></div>
						  </StyledTableCell>
						  <StyledTableCell><i>1.01</i></StyledTableCell>
						  <StyledTableCell>0</StyledTableCell>
						  <StyledTableCell><button type="button" style={{color:'red'}} className="btn code-dialog btn-delete" onClick={() => { if (window.confirm('Are you sure you want to Delete this Layout?')) this.deleteLayout(sheetGuid)}}><i className="fa fa-trash"></i></button> </StyledTableCell>
						</TableRow>)
					  })}
					</TableBody>
				  </Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 20, 50, 100]}
					component="div"
					count={childSheets.length}
					rowsPerPage={this.state.rowsPerPage}
					page={this.state.page}
					onPageChange={this.handleChangePage}
					onRowsPerPageChange={this.handleChangeRowsPerPage}
				  />
				</div>
				:null}
				
				{childSheets ?
				 <div className="mds-popup">
					{childSheets.map((sheet, i) => {	
						let sheetGuid = sheet.LayoutGuid;
						let friendlyName = layoutFriendlyName.get(sheetGuid);
						let mdsFilestr = '';
						if(layoutMdsFiles.get(sheetGuid) != undefined){
							mdsFilestr = MdsFiles.map((msheet, i) => {
								return (
									<tr key={i}><td>{msheet.MDSName}</td><td>{msheet.MDSGuid}</td><td>{msheet.Version}</td></tr>
								);
							})
						}	
						
						return(<div key={'a-'+i} className="modal" id={"layoutDataModal"+sheetGuid} role="dialog">
								<div className="modal-dialog modal-lg">
									<div className="modal-content">
									  <div className="modal-header">
										<h5> Layout Name: {sheet.LayoutName} </h5>
										<button type="button" className="close" data-dismiss="modal">&times;</button>
									  </div>
									  <div className="modal-body">
											<table className="layout-tbl table-bordered">
											  <tr>
												<th>Layout Friendly Name</th>
												<th>Layout GUID</th>
												<th>Version</th>
											  </tr>
											  <tr>
												<td>{friendlyName ? friendlyName : sheet.LayoutName}</td>
												<td>{sheetGuid}</td>
												<td>{sheet.Version}</td>
											  </tr>
											  <tr>&nbsp;</tr>
											  <tr>
												<th>MDS Name</th>
												<th>MDS GUID</th>
												<th>Version</th>
											  </tr>
											  {mdsFilestr}
											</table>
									  </div>
								</div>
							</div>
						</div>)
					})}
					</div>
				:null}
			</div>
		);	
	}	
}
