import React, { Fragment, useState } from 'react';
import $ from 'jquery';
import axios  from 'axios';
import { makeStyles }   from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@material-ui/icons/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: '10px 0px'
    },
    fileGrid: {
        border: '1px solid #c5c5c5',
        margin: '10px -10px',
    },
    inputFile: {
        marginTop: '20px',
    },
    uploadButton: {
        borderRadius: '20px',
    },
    spinner: {
        marginLeft: '15px'
    }
}));

export default function FieldPdfApi(props) {
	
	const fieldId = props.data.fieldId;
	const pdfs = props.apiPdfs;
	const dataField = props.dataField
	
	const classes = useStyles();
    const [title, setTitle]             = useState('');
    const [description, setDescription] = useState('');
    const [fileName, setFileName]       = useState('');
    const [file, setFile]     = useState('');
	
	if(props.dataField){
		let str = props.dataField.split(',');
		let embedCode = '<embed width="100%" height="100%" src='+str[0]+' />';
		let htmlFileData = '<b>Name:</b> '+str[1]+'<br><b>Description:</b> '+str[2]+'<br><b>Date:</b> '+  str[3];
		
		$( "#fileAns"+fieldId ).html(htmlFileData);
		$( "#fileHtmlDiv"+fieldId ).html(embedCode);
		$( "#download-"+fieldId).attr("href", str[0]);
		$( ".apiPdf-list-"+fieldId ).hide();
		//$( ".api-pdf-"+fieldId ).show();
	}
	
	const TableApiPdfs = () =>{
		if(pdfs.length > 0){
			var optionsHtml = pdfs.map(function(val,i) {
				return (
					<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}><TableCell width="80px"><input type='radio' name="apiP" className={fieldId+"_apiP"} value={val.id} /><i className="fas fa-file-pdf" aria-hidden="true"></i></TableCell><TableCell>{val.name}</TableCell><TableCell>{val.description}</TableCell></TableRow>
				);
			})
		}
		
		return <TableContainer component={Paper}>
		  <Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
			<TableBody>
				{optionsHtml}
			</TableBody>
		  </Table>
		</TableContainer>
		
	}
	
	const handleChange = (files) => {
        setFileName(files[0].name);
        setFile(files[0]);
    }

	const replacePdfApi=(fieldId)=>{
		$( "#fileAns"+fieldId ).html('');
		$( "#fileHtmlDiv"+fieldId ).html('');
		$( ".apiPdf-list-"+fieldId ).show();
		$( ".api-pdf-"+fieldId ).hide();
	}
	
	const uploadPdfApi=(fieldId)=>{
		let pdfId = $("."+fieldId+"_apiP:checked").val();
		let aname = '';
		let desc = '';
		let pdf = '';
		if(pdfId){
			pdfs.map(function(val,i) {					
				if(pdfId == val.id){
					aname = val.name;
					desc = val.description;
					pdf = val.url;
				}
			})
			let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
			date = date.replaceAll('/','-');
			
			let embedCode = '<embed width="100%" height="100%" src='+pdf+' />';
			let htmlFileData = '<b>Name:</b> '+aname+'<br><b>Description:</b> '+desc+'<br><b>Date:</b> '+  date;
			
			$( "#fileAns"+fieldId ).html(htmlFileData);
			$( "#fileHtmlDiv"+fieldId ).html(embedCode);
			$( "#download-"+fieldId).attr("href", pdf);
			$( ".apiPdf-list-"+fieldId ).hide();
			$( ".api-pdf-"+fieldId ).show();
			
			let res = pdf+','+aname+','+desc+','+date;
			props.func(fieldId,res);
			setTitle(aname);
			setDescription(desc);
			
		}else{
			alert('Please select data from the list!');
		}
	}
	
	const uploadSubmit = () => {
		let ApiUrl = $('#ApiUrl').val();
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		//let blobURL = URL.createObjectURL(file);
		let fileType = fileName.split('.').pop();
		//let embedCode = '<embed width="100%" height="100%" src='+blobURL+' />';
		//let htmlFileData = '<b>Name:</b> '+title+'<br><b>Description:</b> '+description+'<br><b>Date:</b> '+  date;
		
		if(file && fileType == 'pdf'){
			
			let formData = new FormData();
			formData.append('file', file);
			formData.append('name', title);
			formData.append('description', description);
			
			let url = ApiUrl+'uploadfile/file_upload1.php';
	
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				let data = response.data;
				let path =data[2];
				let embedCode = '<embed width="100%" height="100%" src='+path+' />';
				let res = path+','+title+','+description+','+date;
				props.func(fieldId,res);
					
				$( "#fileHtmlDiv"+fieldId ).html(embedCode);
				$( ".apiPdf-list-"+fieldId ).hide();
				$( ".pdf-upload-form-"+fieldId ).hide();
				$( ".api-pdf-"+fieldId ).show();

			})
			.catch(error => {
				console.log('error::', error);
			}) 
		}else{
			alert('Please upload the PDF file!');
		}
		
    }
	
	const replacePdfLocal=(fieldId)=>{
		$( ".pdf-upload-form-"+fieldId ).show();
	}
	
	return (<div>
		<div>
			<div className="pdf-api-dev" id={'fileHtmlDiv'+fieldId}></div>
			<div id={'fileAns'+fieldId}></div>
		</div>
		<div className={"apiPdf-list apiPdf-list-"+fieldId}>
		<div className="apiPdf-table">
		<TableApiPdfs/>
		</div>
		<button type="button" onClick={() =>uploadPdfApi(fieldId)} className="btn btn-primary">Load file into form from list above</button>
		</div>
		
		<div className={"upload-btn-type api-pdf-"+fieldId} style={{ display: "none" }}><button type="button" className="btn btn-primary" onClick={() =>replacePdfApi(fieldId)}>Replace with new file from list</button>
			&nbsp;<a id={"download-"+fieldId} href={''} className="file-link btn code-dialog" target="_blank"><i className="fa fa-cloud-download" aria-hidden="true"></i></a>
			<button type="button" style={{color:'red'}} className="btn code-dialog btn-delete "><i className="fa fa-trash"></i></button>
			&nbsp;
			<button type="button" className="btn btn-primary" onClick={() =>replacePdfLocal(fieldId)}>Upload and Replace</button>
			<Fragment>
				<form className={"makeStyles-root-2 pdf-upload-form-"+fieldId} noValidate autoComplete="off" style={{ display: "none" }}>
					<Grid container spacing={3} className={classes.fileGrid}>
						<Grid item xs={12} className={classes.inputFile}>
							<Button
								variant="contained"
								component="label"
								color="secondary"
								className={classes.uploadButton}
								>
								<AddIcon /> Select a File
								<input
									type="file"
									name="file" 
									style={{ display: "none" }}
									onChange={ (e) => handleChange(e.target.files) }
								/>
							</Button>
							{ fileName && (<Box component="span" display="block">{fileName}</Box>) }
						</Grid>
						
						<Grid item xs={12}>
							<Button variant="contained" onClick={() =>uploadSubmit()}>Upload File</Button>
						</Grid>
					</Grid>
				</form>
			</Fragment>
		</div>										
	</div>);
}
